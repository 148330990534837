import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

const iconSizes = MainTheme.iconSizes
const iconTimer = MainTheme.iconTimer
const timeBoxWidths = MainTheme.timeBoxWidths

export const container = makeStyles(() => ({
    main:{
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.iconSizes.xs,             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.iconSizes.sm,            
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.iconSizes.md,            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            width: '100%',
            opacity:'0.95',
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:iconSizes.xl,    
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:iconSizes.xxl,          
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:iconSizes.xxxl,           
        },
        [MainTheme.breakpoints.up('xxxxl')]: {
            fontSize:iconSizes.xxxxl,                
        },
    },
    icon:{
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.iconSizes.xs,             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.iconSizes.sm,            
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.iconSizes.md,            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            fontSize: iconSizes.lg,
            color:'white',
            height: iconTimer.height.lg,
            width: iconTimer.width.lg
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:iconSizes.xl,    
            height: iconTimer.height.xl,
            width: iconTimer.width.xl        
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:iconSizes.xxl,   
            height: iconTimer.height.xxl,
            width: iconTimer.width.xxl         
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:iconSizes.xxxl,   
            height: iconTimer.height.xxxl,
            width: iconTimer.width.xxxl         
        },
        [MainTheme.breakpoints.up('xxxxl')]: {
            fontSize:iconSizes.xxxxl,   
            height: iconTimer.height.xxxxl,
            width: iconTimer.width.xxxxl           
        },
    },
}))