import React from 'react'
import styles from './styles.js'
import { Button } from '@mui/material'

const EmabalseButton = ({_name, _id, onClick}) => {
    const buttonStyle = styles().buttonStyle

    return (
        <Button name={_name} className={`${buttonStyle}`} variant='outlined' color='primary' onClick={() => onClick(_name, _id)}>
            {_name}
        </Button>
    )
}

export default EmabalseButton