import React, { useRef, useEffect, useState } from 'react'
import { MapContainer, TileLayer} from 'react-leaflet'
import ColorBar from '../ColorBar/index.js'
import { io } from "socket.io-client"
import styles from './styles.js'
import GeotiffLayer from '../GeoTiff'
import markSlider from './markSlider.js';
import LeafletRuler from '../Ruler/LeafletRuler.jsx'
import getColor from './colorBands.js'
import getArrayBuffer from './_getArrayBuffer.js'
import 'leaflet/dist/leaflet.css'
import { product_type, goes_bands, goes_level2 } from '../../config/products.js'
import TimeBox from '../TimeBox/index.js'
import Brightness6Icon from '@mui/icons-material/Brightness6'
import OpacityIcon from '@mui/icons-material/Opacity'
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LightModeIcon from '@mui/icons-material/LightMode';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import ControlTiff from '../ControlTiff/index.js'
import AreaSelect from "../AreaSelect";
import "leaflet-area-select";
import Flashes from '../Flashes/index.js'
import {flashes, groups} from '../Flashes/data'
import FloatButton from './floatButton.js'
import BoltIcon from '@mui/icons-material/Bolt';

const defaultCenter = [-9.598845, -76.017351]
const defaultZoom = 6
const initVis = {name:'Reflectancia', min:0, max:1, step:0.01, actualValue: [0, 1]}
const initIr = {name:'Temp. Brillo (ºC)', min:-100, max:100, step:0.01, actualValue: [-100, 100]}
const initRrqpe = {name:'Precipitacion (mm/h)', min:0, max:35, step:0.01, actualValue: [0, 35]}

const Maps = ({socketServer, socketWs}) => {  
    const socketRef = useRef()

    const [varSlider, setVarSlider] = useState({name:'Reflectancia', min:0, max:1, step:0.01, actualValue: [0, 1]})
    const [varSliderTemp, setVarSliderTemp] = useState({name:'Reflectancia', min:0, max:1, step:0.01, actualValue: [0, 1]})
    const [resolutionSlider, setResolutionSlider] = useState({min:64, max: 256, step:64, actualValue: 128})
    const [brightSlider, setBrightSlider] = useState({min:0, max: 100, step:0.01, actualValue: 50})
    const [brightActive, setBrightActive] = useState(false)
    const [opacitySlider, setOpacitySlider] = useState({min:0, max: 1, step:0.01, actualValue: 1})

    const [drawn, setDrawn] = useState(false)
    const [productType, setProductType] = useState('')
    const [l2Product, setL2Product] = useState({product:'', var: ''})
    const [arrayBuffer, setArrayBuffer] = useState([])
    const [withArrayBuffer, setWithArrayBuffer] = useState(false)
    const [arrayLen, setArrayLen] = useState(0)
    const [arrayTemp, setArrayTemp] = useState([])
    const [chunks, setChunks] = useState([])
    const [band, setBand] = useState('01')
    const [times, setTimes] = useState([])
    const [withTimes, setWithTimes] = useState(false)
    const [selectedTime, setSelectedTime] = useState('')
    const [optColors, setOptColors] = useState(null)
    const [irSelected, setIrSelected] = useState(false)
    const [ready, setReady] = useState(false)
    const [endBuffer, setEndBuffer] = useState(false)
    const [transferStarted, setTransferStarted] = useState(false)
    const [byteFrom, setByteFrom] = useState(0)
    const [sizeTransfered, setSizeTransfered] = useState(0)
    const [loadingFile, setLoadingFile] = useState(false)
    const [fileLoaded, setFileLoaded] = useState(false)
    const [iconSlider, setIconSlider] = useState(ThermostatIcon)

    const [tileLayer, setTileLayer] = useState([
        {name: 'Open Street Map', baseUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', url:'https://www.openstreetmap.org/copyright', quote:'OpenStreetMap'},
        {name: 'Open Topographic Map', baseUrl: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', url:'https://www.openstreetmap.org/copyright', quote:'OpenStreetMap'}
    ])

    const [tileIndex, setTileIndex] = useState(0)

    const container = styles().container
    const containerRight = styles().containerRight
    const timeBoxContainer = styles().timeBoxContainer
    const timeBoxContainerFlash = styles().timeBoxContainerFlash

    const mapRef = useRef()
    const { current = {} } = mapRef;
    const { leafletElement:  map} = current;    

    /******************** Flashes ***********************/
    const [selectedTimeFlash, setSelectedTimeFlash] = useState(null)
    const [timesFlash, setTimesFlash] = useState(null)
    const [withTimesFlash, setWithTimesFlash] = useState(false)
    const [withFlashes, setWithFlashes] = useState(false)    
    /****************************************************/

    /******************** TimeBox ***********************/
    const [bussy, setBussy] = useState(false)
    /****************************************************/

    const handleFlashes = () => {
        if(!withFlashes)
            setWithFlashes(true)
        else
            setWithFlashes(false)
    }

    const handleLoad = async () => {
        if(selectedTime === ''){
            console.log('Seleccionar fecha')
            return
        }
        setLoadingFile(true)
        setFileLoaded(false)
        setVarSlider(varSliderTemp)
        const color = getColor({type: productType, l2Product, band, min: varSliderTemp.actualValue[0], max: varSliderTemp.actualValue[1], bright: brightSlider.actualValue})
        setOptColors({...color, resolution: resolutionSlider.actualValue, opacity: opacitySlider.actualValue})
        setEndBuffer(false)
        setTransferStarted(true)
        setWithArrayBuffer(false)
        if(productType === 'band'){        
            setBrightActive(!irSelected)
            setIconSlider(irSelected?ThermostatIcon:LightModeIcon)
            socketRef.current.emit('getBandBuffer', {byteFrom, date: selectedTime.date, time: selectedTime.time, band:band})
        }
        else if(productType === 'L2'){ 
            setBrightActive(false)
            setIconSlider(ThunderstormIcon)
            socketRef.current.emit('getL2Buffer', {byteFrom, date: selectedTime.date, time: selectedTime.time, l2Product:l2Product})
        }
    }

    const handleProducts = async (index) => {
        setProductType(product_type[index].product)
    }

    const handleBand = async (index) => {
        const band = goes_bands[index].product
        if(band === '01' || band === '02' || band === '03' || band === '04' || band === '05' || band === '06'){
            setIrSelected(false)
            setVarSliderTemp(initVis)
        }else{
            setIrSelected(true)
            setVarSliderTemp(initIr)
        }
        setBand(band)
        socketRef.current.emit('getTimeBands', band)
    }

    const handleL2 = async (index) => {        
        const payload = {product:goes_level2[index].product, var:goes_level2[index].var}
        setVarSliderTemp(initRrqpe)
        setL2Product(payload)
        socketRef.current.emit('getL2Time', payload)
    }

    useEffect(() => {
        if(drawn){
            var dst = new ArrayBuffer(arrayLen)
            new Uint8Array(dst).set(new Uint8Array(arrayTemp))
            setArrayBuffer(dst)
            const color = getColor({type: productType, l2Product, band, min: varSlider.actualValue[0], max: varSlider.actualValue[1], bright: brightSlider.actualValue})
            setOptColors({...color, resolution: resolutionSlider.actualValue, opacity: opacitySlider.actualValue})
        }
    }, [varSlider, opacitySlider, brightSlider, resolutionSlider])
    
    useEffect(() => {  
        socketRef.current = io(`wss://${socketServer}/geo-${socketWs}`, {
            path: '/geobrowser/products/',
            auth:{
                token: localStorage.getItem('token')
            }
        })

        socketRef.current.on('setTimeBands', times => {
            setTimes(times)
            setSelectedTime(times[6])
            setWithTimes(true)         
        })     
        socketRef.current.on('setL2Time', times => {
            setTimes(times)
            setSelectedTime(times[6])
            setWithTimes(true)       
        })
        
        if(transferStarted){
            if(endBuffer){
                var _arrTemp = new Uint8Array(arrayLen)                
                chunks.forEach(item => {
                    _arrTemp.set(new Uint8Array(item.chunk), item.offset)
                })
                getArrayBuffer(_arrTemp).then(res => {
                    var dst = new ArrayBuffer(res.arrayBufferLen)
                    new Uint8Array(dst).set(new Uint8Array(res.arrayBuffer))
                    setArrayBuffer(dst)
                    var dst2 = new ArrayBuffer(res.arrayBufferLen)
                    new Uint8Array(dst2).set(new Uint8Array(res.arrayBuffer))
                    setArrayTemp(dst2)
                    setWithArrayBuffer(true)
                    setChunks([])
                    setTransferStarted(false)
                    setSizeTransfered(0)
                    setLoadingFile(false)
                    setFileLoaded(true)
                    ready ? setReady(false) : setReady(true)
                })
            }else{
                if(productType === 'band'){ 
                    socketRef.current.emit('getBandBuffer', {byteFrom, date: selectedTime.date, time: selectedTime.time, band:band})
                }else if (productType === 'L2'){
                    socketRef.current.emit('getL2Buffer', {byteFrom, date: selectedTime.date, time: selectedTime.time, l2Product})
                }
            }
        }

        socketRef.current.on('setBandBuffer', async args => {
            var newByteFrom = byteFrom+args.bufferSize
            setChunks([...chunks, {chunk:args.chunk, offset:byteFrom}])
            setSizeTransfered(newByteFrom)
            setArrayLen(args.arraySize)
            if(!args.finished){
                setByteFrom(newByteFrom)

            }else{
                setByteFrom(0)
                setEndBuffer(true)
            }
            ready ? setReady(false) : setReady(true)
        })

        socketRef.current.on('setL2Buffer', async args => {
            var newByteFrom = byteFrom+args.bufferSize
            setChunks([...chunks, {chunk:args.chunk, offset:byteFrom}])
            setSizeTransfered(newByteFrom)
            setArrayLen(args.arraySize)
            if(!args.finished){
                setByteFrom(newByteFrom)

            }else{
                setByteFrom(0)
                setEndBuffer(true)
            }
            ready ? setReady(false) : setReady(true)
        })

        return () => {
            socketRef.current.off('setTimeBands')
            socketRef.current.off('setL2Time')
            socketRef.current.off('setBandBuffer')
            socketRef.current.off('setL2Buffer')
            socketRef.current.disconnect()
        }
    }, [ready])    

    const buttonFlashes = {
        onClick: handleFlashes,
        icon: BoltIcon
    }

    var controlProps = {
        conditions:{
            productType: productType,
            loadingFile: loadingFile,
            withControls: true
        },
        productType:{
            disabled: loadingFile,
            fn: handleProducts,
            products: product_type,
            _hint: 'Seleccione una Categoria'
        },
        products_bands:{
            active: true,
            disabled: loadingFile,
            fn: handleBand,
            products: goes_bands,
            _hint: 'Seleccione una Banda'
        },
        products_l2: {  
            active: true,
            disabled: loadingFile,
            fn: handleL2,
            products: goes_level2, 
            _hint: 'Seleccione un Producto'
        },
        opacity: { 
            active: true,
            disabled: loadingFile || !fileLoaded,
            name: 'Opacidad',
            sliderOpts: opacitySlider,
            configSlider: setOpacitySlider,
            defaultValue: opacitySlider.actualValue,
            min: opacitySlider.min,
            max: opacitySlider.max,
            step: opacitySlider.step,
            marks: markSlider.opacity,
            icon: OpacityIcon
        },
        brightness: {
            active: true,
            disabled: loadingFile || !fileLoaded || !brightActive,
            name: 'Brillo',
            sliderOpts: brightSlider,
            configSlider: setBrightSlider,
            defaultValue: brightSlider.actualValue,
            min: brightSlider.min,
            max: brightSlider.max,
            step: brightSlider.step,
            marks: markSlider.bright,
            icon: Brightness6Icon
        },
        resolution: {
            active: true,
            disabled: loadingFile || !fileLoaded,
            name: 'Resolucion',
            sliderOpts: resolutionSlider,
            configSlider: setResolutionSlider,
            defaultValue: resolutionSlider.actualValue,
            min: resolutionSlider.min,
            max: resolutionSlider.max,
            step: resolutionSlider.step,
            marks: markSlider.resolution,
            icon: AspectRatioIcon
        },
        variable: {
            active: true,
            disabled: loadingFile || !fileLoaded,
            name: varSlider.name,
            sliderOpts: varSlider,
            configSlider: setVarSlider, 
            defaultValue: varSlider.actualValue, 
            min: varSlider.min,
            max: varSlider.max,
            step: varSlider.step,
            marks: [{value: varSlider.min, label: `${varSlider.min}`}, {value: varSlider.max, label: `${varSlider.max}`}],
            icon: iconSlider
        },
        loadButton:{
            onClick: handleLoad
        },
        progressBar:{
            progressValue:!isFinite(sizeTransfered/arrayLen)?0:((sizeTransfered/arrayLen)*100)
        },
        setWithTimes: setWithTimes
    }

    const flashesProps = {
        setSelectedTimeFlash,
        selectedTimeFlash,
        setTimesFlash,
        timesFlash,
        setWithTimesFlash,
        withTimesFlash,
    }

    return (
        <div className={`${container}`}>
            <FloatButton key={0} name='glmButton' props={buttonFlashes} withFlashes={withFlashes}/>
            {withTimes?<ColorBar props={{productType, l2Product, band, min:varSliderTemp.actualValue[0], max: varSliderTemp.actualValue[1]}} />:null}
            <ControlTiff controlProps={controlProps}/>
            {withTimes?<div className={`${timeBoxContainer}`}><TimeBox disabled={bussy} dateTimes={times} setDateTime={setSelectedTime} actualDateTime={selectedTime}/></div>:null} 
            {(withTimesFlash & withFlashes)?<div className={`${timeBoxContainerFlash}`}><TimeBox disabled={bussy} dateTimes={timesFlash} setDateTime={setSelectedTimeFlash} actualDateTime={selectedTimeFlash}/></div>:null}
            <div className={`${containerRight}`}>
                <MapContainer ref={map} center={defaultCenter} zoom={defaultZoom}>
                    <TileLayer url={`${tileLayer[tileIndex].baseUrl}`} attribution={`&copy; <a href=&quot;${tileLayer[tileIndex].url}&quot;>${tileLayer[tileIndex].quote}</a> contributors`} />
                    <LeafletRuler />
                    <AreaSelect />           
                    {withFlashes?<Flashes flashes={flashes} groups={groups} socketRef={socketRef} props={flashesProps} setBussy={setBussy} />:null}
                    {!withArrayBuffer ? null : 
                        <GeotiffLayer arrayBuffer={arrayBuffer} options={optColors} setDrawn={setDrawn} setFileLoaded={setFileLoaded} setWithFlashes={setWithFlashes}/>}
                </MapContainer>
            </div>
        </div>
    )
}

export default Maps