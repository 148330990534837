import tokenAuth from '../../config/tokenAuth'
import authAxios from '../../config/auth'

export const usuarioAutenticado = async () => {
    const token = localStorage.getItem('token')
    if(token){
        tokenAuth(token)
    }
    try {
        const response = await authAxios.get('/api/auth')
        return {username: response.data.username, id: response.data.id}
    } catch (error) {
        return error
    }
}

//Cuando un usuario inicia sesion
export const startSession = async datos =>{
    try {
        const response = await authAxios.post('/api/auth', datos)
        localStorage.setItem('token', response.data.token)
        const {username, id} = await usuarioAutenticado()
        return {username, id}
    } catch (error) {
        localStorage.removeItem('token')
        const dataRes = error.response.data
        if(dataRes.errores){
            return {message: dataRes.errores[0].msg}
        }else if(dataRes.message){
            return {message: dataRes.message}
        }else{
            return
        }
    }
}

//Cierra sesión de usuario
export const cerrarSesion = () =>{
}