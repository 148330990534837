import {makeStyles, styled} from '@mui/styles'
import { Slider } from '@mui/material'
import MainTheme from '../../MainTheme'

const selectHeights = MainTheme.selectHeights
const paddingSizes = MainTheme.paddingSizes
const thumbSliderSizes = MainTheme.thumbSliderSizes
const valueLabelSizes = MainTheme.valueLabelSizes
const textSizes = MainTheme.textSizes

export const container = makeStyles(() => ({
    main:{
        [MainTheme.breakpoints.up('xs')]: { 
             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            
        },
        [MainTheme.breakpoints.up('md')]: {  
            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'space-around',
            alignItems:'center',
            width: '100%',
            paddingTop:paddingSizes.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            paddingTop:paddingSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            paddingTop:paddingSizes.xxl,            
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            paddingTop:paddingSizes.xxxl,            
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            paddingTop:paddingSizes.xxxxl,             
        },
    },
    icon:{
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.iconSizes.xs,             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.iconSizes.sm,            
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.iconSizes.md,
            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'flex-start',
            width: '2%',
            color:'white',
            paddingTop:'10px',
            fontSize:MainTheme.iconSizes.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:MainTheme.iconSizes.xl,
            
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:MainTheme.iconSizes.xxl,
            
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:MainTheme.iconSizes.xxxl,
            
        },
        [MainTheme.breakpoints.up('xxxxl')]: {
            fontSize:MainTheme.iconSizes.xxxxl,  
            
        },
    },
    slider:{
        [MainTheme.breakpoints.up('xs')]: { 
             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            
        },
        [MainTheme.breakpoints.up('md')]: {  
            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "column",
            justifyContent:'center',
            alignItems:'center',
            width: '65%',
            paddingTop:'10px',
            height: selectHeights.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            height: selectHeights.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            height: selectHeights.xxl,
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            height: selectHeights.xxxl,
        },
        [MainTheme.breakpoints.up('xxxxl')]: {  
            height: selectHeights.xxxxl,
        },
    },
}))

const colors = {
    main: '#26251c',
    second: '#eb0a44',
    third: '#f2643d',
    fourth: '#f2a73d',
    fifth: '#a0e8b7'
}

export const CustomSlider = styled(Slider)(() => ({    
    '& .MuiSlider-thumb': {
        backgroundColor: '#fff',
        border: `1px solid white`,
    },
    '&.MuiSlider-root.Mui-disabled':{
        '& .MuiSlider-rail': {
            color: '#d8d8d8',
            opacity: 1,
            height: 3,
        },
        '& .MuiSlider-track': {
            height: 4,
            color: '#d8d8d8',
        },
    },
    '& .MuiSlider-track': {
        height: 4,
        color: colors.second,
    },
    '& .MuiSlider-rail': {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
    '& .MuiSlider-markLabel':{
        color: 'white',
    },
    '& .MuiSlider-mark':{
        color: colors.second,
    },
    '& .MuiSlider-valueLabel': {
        backgroundColor: colors.second,
    },
    [MainTheme.breakpoints.up('xs')]: { 
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.xs, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.xs, 
        },         
    },
    [MainTheme.breakpoints.up('sm')]: {  
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.sm, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.sm, 
        },        
    },
    [MainTheme.breakpoints.up('md')]: {  
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.md, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.md, 
        },        
    },
    [MainTheme.breakpoints.up('lg')]: {
        '& .MuiSlider-thumb': {
            height: thumbSliderSizes.height.lg,
            width: thumbSliderSizes.width.lg,
            '&:hover': {
                boxShadow: `0 0 0 ${thumbSliderSizes.shadow.lg}px rgba(256, 0, 0, 0.16)`,
            },
        },
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.lg, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.lg, 
        },     
        '& .MuiSlider-valueLabel': {
            height: valueLabelSizes.height.lg,
            width: valueLabelSizes.width.lg,
            fontSize: textSizes.lg
        },
    },
    [MainTheme.breakpoints.up('xl')]: {
        '& .MuiSlider-thumb': {
            height: thumbSliderSizes.height.xl,
            width: thumbSliderSizes.width.xl,
            '&:hover': {
                boxShadow: `0 0 0 ${thumbSliderSizes.shadow.xl}px rgba(256, 0, 0, 0.16)`,
            },
        },
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.xl, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.xl, 
        },        
        '& .MuiSlider-valueLabel': {
            height: valueLabelSizes.height.xl,
            width: valueLabelSizes.width.xl,
            fontSize: textSizes.xl
        },
    },
    [MainTheme.breakpoints.up('xxl')]: {  
        '& .MuiSlider-thumb': {
            height: thumbSliderSizes.height.xxl,
            width: thumbSliderSizes.width.xxl,
            '&:hover': {
                boxShadow: `0 0 0 ${thumbSliderSizes.shadow.xxl}px rgba(256, 0, 0, 0.16)`,
            },
        },
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.xxl, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.xxl, 
        },         
        '& .MuiSlider-valueLabel': {
            height: valueLabelSizes.height.xxl,
            width: valueLabelSizes.width.xxl,
            fontSize: textSizes.xxl
        },    
    },
    [MainTheme.breakpoints.up('xxxl')]: {  
        '& .MuiSlider-thumb': {
            height: thumbSliderSizes.height.xxxl,
            width: thumbSliderSizes.width.xxxl,
            '&:hover': {
                boxShadow: `0 0 0 ${thumbSliderSizes.shadow.xxxl}px rgba(256, 0, 0, 0.16)`,
            },
        },
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.xxxl, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.xxxl, 
        },          
        '& .MuiSlider-valueLabel': {
            height: valueLabelSizes.height.xxxl,
            width: valueLabelSizes.width.xxxl,
            fontSize: textSizes.xxxl
        },      
    },
    [MainTheme.breakpoints.up('xxxxl')]: {  
        '& .MuiSlider-thumb': {
            height: thumbSliderSizes.height.xxxxl,
            width: thumbSliderSizes.width.xxxxl,
            '&:hover': {
                boxShadow: `0 0 0 ${thumbSliderSizes.shadow.xxxxl}px rgba(256, 0, 0, 0.16)`,
            },
        },
        '& .MuiSlider-markLabel':{
            fontSize:MainTheme.textSizes.xxxxl, 
        },
        '& .MuiSlider-mark':{
            fontSize:MainTheme.textSizes.xxxxl, 
        },             
        '& .MuiSlider-valueLabel': {
            height: valueLabelSizes.height.xxxxl,
            width: valueLabelSizes.width.xxxxl,
            fontSize: textSizes.xxxxl
        },   
    },
}))