import {styled} from '@mui/styles'
import MainTheme from '../../MainTheme'
import {Button} from '@mui/material'

const textSizes = MainTheme.textSizes
const paddingSizes = MainTheme.paddingSizes

const colors = {
    main: '#26251c',
    second: '#eb0a44',
    third: '#f2643d',
    fourth: '#f2a73d',
    fifth: '#a0e8b7'
}

export const CustomButton = styled(Button)(() => ({
    "&.MuiButtonBase-root": {
        width:'90%',
        backgroundColor: colors.second,
        '&:hover': {
            backgroundColor: colors.main,
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
    },
    [MainTheme.breakpoints.up('xs')]: { 
        "&.MuiButtonBase-root": {
            fontSize: textSizes.xs,
            marginTop: paddingSizes.xs
        },
    },
    [MainTheme.breakpoints.up('sm')]: {  
        "&.MuiButtonBase-root": {
            fontSize: textSizes.sm,
            marginTop: paddingSizes.sm
        },
    },
    [MainTheme.breakpoints.up('md')]: {  
        "&.MuiButtonBase-root": {
            fontSize: textSizes.md,
            marginTop: paddingSizes.md
        },  
    },
    [MainTheme.breakpoints.up('lg')]: {
        "&.MuiButtonBase-root": {
            fontSize: textSizes.lg,
            marginTop: paddingSizes.lg
        },
    },
    [MainTheme.breakpoints.up('xl')]: {
        "&.MuiButtonBase-root": {
            fontSize: textSizes.xl,
            marginTop: paddingSizes.xl
        },
    },
    [MainTheme.breakpoints.up('xxl')]: {  
        "&.MuiButtonBase-root": {
            fontSize: textSizes.xxl,
            marginTop: paddingSizes.xxl
        },
    },
    [MainTheme.breakpoints.up('xxxl')]: {  
        "&.MuiButtonBase-root": {
            fontSize: textSizes.xxxl,
            marginTop: paddingSizes.xxxl
        },
    },
    [MainTheme.breakpoints.up('xxxxl')]: {  
        "&.MuiButtonBase-root": {
            fontSize: textSizes.xxxxl,
            marginTop: paddingSizes.xxxxl
        },   
    },
}))