import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

export const textBox = makeStyles(() => ({
    label:{
        color: MainTheme.palette.secondary.contrastText,
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.textColorBarSizes.xs, 
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.textColorBarSizes.sm,
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.textColorBarSizes.md,
        },
        [MainTheme.breakpoints.up('lg')]: {
            fontSize:MainTheme.textColorBarSizes.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:MainTheme.textColorBarSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:MainTheme.textColorBarSizes.xxl,
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:MainTheme.textColorBarSizes.xxxl,
        },
        [MainTheme.breakpoints.up('xxxxl')]: {  
            fontSize:MainTheme.textColorBarSizes.xxxxl,
        },
    },
}))