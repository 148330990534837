const getColor = ({type, band, l2Product, min, max, bright}) => {
    if(type === 'band'){
        if(band === '01' || band === '02' || band === '03' || band === '04' || band === '05' || band === '06'){
            return({
                pixelValuesToColorFn: values => 
                values[0] >= min & values[0] <= max ? `rgb(${values[0]*255+0.996*bright},${values[0]*255+0.996*bright},${values[0]*255+0.996*bright})`: 
                values[0] > max ? `rgb(255,255,255)`:`rgba(0,0,0,0)`,
                bright
            })
        }else if(band === '07'){
            return({
                pixelValuesToColorFn: values => 
                ((values[0] >= -100 & values[0] < 0) & (values[0]>=min & values[0]<=max))? `rgb(0,${-2.55*values[0]},${-2.55*values[0]})`: 
                ((values[0] >= 0 & values[0] < 100) & (values[0]>=min & values[0]<=max))?`rgb(${2.55*values[0]},${2.55*values[0]},${2.55*values[0]})`: `rgba(0,0,0,0)`
            })
        }else if(band === '08' || band === '09' || band === '10'){
            return({
                pixelValuesToColorFn: values => 
                ((values[0] >= -100 & values[0] < -95) & (values[0]>=min & values[0]<=max))? `rgb(255,255,255)`: 
                ((values[0] >= -95 & values[0] < -70) & (values[0]>=min & values[0]<=max))?`rgb(${-8*values[0]-560},${-4.2*values[0]-144},${-5.6*values[0]-302})`:
                ((values[0] >= -70 & values[0] < -50) & (values[0]>=min & values[0]<=max))?`rgb(${12.75*values[0]+892.5}, ${5.25*values[0]+517.5}, ${8.25*values[0]+667.5})`:
                ((values[0] >= -50 & values[0] < -30) & (values[0]>=min & values[0]<=max))?`rgb(${-12.75*values[0]-382.5}, ${-12.75*values[0]-382.5}, ${-4.75*values[0]+17.5})`:
                ((values[0] >= -30 & values[0] < -20) & (values[0]>=min & values[0]<=max))?`rgb(${175*values[0]+5330}, ${175*values[0]+5330}, 0)`:
                ((values[0] >= -20 & values[0] < 0) & (values[0]>=min & values[0]<=max))?`rgb(${-0.75*values[0]+240}, ${-8.75*values[0]+80}, 0)`:
                ((values[0] >= 0 & values[0] < 100) & (values[0]>=min & values[0]<=max))?`rgb(0, 0, 0)`:`rgba(0,0,0,0)`
            })
        }else if(band === '11'){
            return({
                pixelValuesToColorFn: values => 
                ((values[0] >= -100 & values[0] < -90) & (values[0]>=min & values[0]<=max))? `rgb(0,0,0)`: 
                ((values[0] >= -90 & values[0] < -75) & (values[0]>=min & values[0]<=max))?`rgb(255,255,255)`:
                ((values[0] >= -75 & values[0] < -70) & (values[0]>=min & values[0]<=max))?`rgb(0, 0, ${51*values[0]+3825})`:
                ((values[0] >= -70 & values[0] < -50) & (values[0]>=min & values[0]<=max))?`rgb(${-2.75*values[0]+62.5}, ${-2.75*values[0]+62.5}, ${-2.75*values[0]+62.5})`:
                ((values[0] >= -50 & values[0] < -45) & (values[0]>=min & values[0]<=max))?`rgb(0, ${21*values[0]+1200}, 0)`:
                ((values[0] >= -45 & values[0] < -35) & (values[0]>=min & values[0]<=max))?`rgb(100, 255, ${10.5*values[0]+622.5})`:
                ((values[0] >= -35 & values[0] < -25) & (values[0]>=min & values[0]<=max))?`rgb(${19.5*values[0]+742.5}, 0, ${19.5*values[0]+742.5})`:
                ((values[0] >= -25 & values[0] < -20) & (values[0]>=min & values[0]<=max))?`rgb(${-17*values[0]-170}, 0, 0)`:
                ((values[0] >= -20 & values[0] < -15) & (values[0]>=min & values[0]<=max))?`rgb(${-17*values[0]+510}, ${-17*values[0]+510}, 0)`:
                ((values[0] >= -15 & values[0] < 100) & (values[0]>=min & values[0]<=max))?`rgb(${-2.217*values[0]+221.73}, ${-2.217*values[0]+221.73}, ${-2.217*values[0]+221.73})`:`rgba(0,0,0,0)`,
            })
        }else if(band === '12' || band === '13' || band === '14' || band === '15' || band === '16'){
            return({
                pixelValuesToColorFn: values => 
                ((values[0] >= -100 & values[0] < -85) & (values[0]>=min & values[0]<=max))? `rgb(255,255,255)`: 
                ((values[0] >= -85 & values[0] < -80) & (values[0]>=min & values[0]<=max))?`rgb(${28*values[0]+2495},${26*values[0]+2210},${28*values[0]+2495})`:
                ((values[0] >= -80 & values[0] < -75) & (values[0]>=min & values[0]<=max))?`rgb(${-51*values[0]-3825}, ${-51*values[0]-3825}, ${-51*values[0]-3825})`:
                ((values[0] >= -75 & values[0] < -60) & (values[0]>=min & values[0]<=max))?`rgb(255, ${17*values[0]+1275}, 0)`:
                ((values[0] >= -60 & values[0] < -50) & (values[0]>=min & values[0]<=max))?`rgb(${-25.5*values[0]-1275}, 255, 0)`:
                ((values[0] >= -50 & values[0] < -45) & (values[0]>=min & values[0]<=max))?`rgb(0, ${-51*values[0]-2295}, 255)`:
                ((values[0] >= -45 & values[0] < -40) & (values[0]>=min & values[0]<=max))?`rgb(0, 0, ${51*values[0]+2295})`:
                ((values[0] >= -40 & values[0] < -25) & (values[0]>=min & values[0]<=max))?`rgb(0, ${17*values[0]+680}, 255)`:
                ((values[0] >= -25 & values[0] < 100) & (values[0]>=min & values[0]<=max))?`rgb(${-2.04*values[0]+204},${-2.04*values[0]+204},${-2.04*values[0]+204})`: `rgba(0,0,0,0)`
            })
        }
    }else if(type === 'L2'){     
        if (l2Product.var === 'RRQPE'){
            return({
                pixelValuesToColorFn: values =>
                (values[0] <= 0)?`rgba(0,0,0,0)`:
                ((values[0] > 0 & values[0] <= 12) & (values[0]>=min & values[0]<=max))?`rgb(0,${21.25*values[0]},255)`:
                ((values[0] > 12 & values[0] <= 20) & (values[0]>=min & values[0]<=max))?`rgb(0,255,${-31.875*values[0]+637.5})`:
                ((values[0] > 20 & values[0] <= 23) & (values[0]>=min & values[0]<=max))?`rgb(${85*values[0]-1700}, 255, 0)`:
                ((values[0] > 23 & values[0] <= 35) & (values[0]>=min & values[0]<=max))?`rgb(255, ${-21.25*values[0]+743.75}, 0)`:
                (values[0] > 35)?`rgb(200, 0, 0)`: null
            })
        }

    }
    
}

export default getColor