import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
// import 'leaflet-area-select';

const options = {
    position: "topright",
    circleMarker: {
      color: "blue",
      radius: 2
    },
    lineStyle: {
      color: "black",
      dashArray: "1,6"
    },
    lengthUnit: {
      display: "km",
      decimal: 2,
      factor: null,
      label: "Distance:"
    },
    angleUnit: {
      display: "&deg;",
      decimal: 2,
      factor: null,
      label: "Bearing:"
    }
}

const calc_area = ({f1, l1, f2, l2}) => {    
    var toRadian = Math.PI / 180;
    // haversine formula
    // bearing
    var y = Math.sin((l2 - l1) * toRadian) * Math.cos(f2 * toRadian);
    var x =
        Math.cos(f1 * toRadian) * Math.sin(f2 * toRadian) -
        Math.sin(f1 * toRadian) *
        Math.cos(f2 * toRadian) *
        Math.cos((l2 - l1) * toRadian);
    var brng =
        Math.atan2(y, x) *
        ((options.angleUnit.factor
        ? options.angleUnit.factor / 2
        : 180) /
        Math.PI);
    brng +=
        brng < 0
        ? options.angleUnit.factor
            ? options.angleUnit.factor
            : 360
        : 0;
    // distance
    var R = options.lengthUnit.factor
        ? 6371 * options.lengthUnit.factor
        : 6371; // kilometres
    var deltaF = (f2 - f1) * toRadian;
    var deltaL = (l2 - l1) * toRadian;
    var a =
        Math.sin(deltaF / 2) * Math.sin(deltaF / 2) +
        Math.cos(f1 * toRadian) *
        Math.cos(f2 * toRadian) *
        Math.sin(deltaL / 2) *
        Math.sin(deltaL / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c
    return distance
}

export default function AreaSelect() {
    const map = useMap();    

    useEffect(() => {
        if (!map.selectArea) return;

        map.selectArea.enable();

        map.on("areaselected", (e) => {
            const parts = {
                base:   {
                    f1: e.bounds._northEast.lat,
                    l1: e.bounds._southWest.lng,
                    f2: e.bounds._northEast.lat,
                    l2: e.bounds._northEast.lng
                },
                height: {
                    f1: e.bounds._northEast.lat,
                    l1: e.bounds._southWest.lng,
                    f2: e.bounds._southWest.lat,
                    l2: e.bounds._southWest.lng
                }
            }
            const base_len = calc_area(parts.base)
            const height_len = calc_area(parts.height)
            const area_data = {base: base_len, height: height_len, unit_len:'km', area: base_len*height_len, unit_area: 'km2'}
            const text = `<b>Area: ${area_data.area.toFixed(2)} ${area_data.unit_area}</b>`
            const rectArea = L.rectangle(e.bounds, { color: "blue", weight: 1 })
            .bindTooltip(text,{
                sticky: true,
                offset: L.point(0, -5),
            }).addTo(map);
            rectArea.on('click', () => {
                rectArea.remove()
            })
        });

        const bounds = map.getBounds().pad(-0.25);
        map.selectArea.setValidate((layerPoint) => {
            return bounds.contains(this._map.layerPointToLatLng(layerPoint));
        });
        map.selectArea.setValidate();
    }, [map]);

    return null;
}
