const getColor = ({type, band, l2Product, min, max}) => {
    if(type === 'band'){
        if(band === '01' || band === '02' || band === '03' || band === '04' || band === '05' || band === '06'){
            return({
                valToColor: value => 
                value >= min & value <= max ? `rgb(${value*255},${value*255},${value*255})`: 
                value > max ? `rgb(255,255,255)`:`rgba(0,0,0,0)`
            })
        }else if(band === '07'){
            return({
                valToColor: value => 
                ((value >= -100 & value < 0) & (value>=min & value<=max))? `rgb(0,${-2.55*value},${-2.55*value})`: 
                ((value >= 0 & value < 100) & (value>=min & value<=max))?`rgb(${2.55*value},${2.55*value},${2.55*value})`: `rgba(0,0,0,0)`
            })
        }else if(band === '08' || band === '09' || band === '10'){
            return({
                valToColor: value => 
                ((value >= -100 & value < -95) & (value>=min & value<=max))? `rgb(255,255,255)`: 
                ((value >= -95 & value < -70) & (value>=min & value<=max))?`rgb(${-8*value-560},${-4.2*value-144},${-5.6*value-302})`:
                ((value >= -70 & value < -50) & (value>=min & value<=max))?`rgb(${12.75*value+892.5}, ${5.25*value+517.5}, ${8.25*value+667.5})`:
                ((value >= -50 & value < -30) & (value>=min & value<=max))?`rgb(${-12.75*value-382.5}, ${-12.75*value-382.5}, ${-4.75*value+17.5})`:
                ((value >= -30 & value < -20) & (value>=min & value<=max))?`rgb(${175*value+5330}, ${175*value+5330}, 0)`:
                ((value >= -20 & value < 0) & (value>=min & value<=max))?`rgb(${-0.75*value+240}, ${-8.75*value+80}, 0)`:
                ((value >= 0 & value < 100) & (value>=min & value<=max))?`rgb(0, 0, 0)`:`rgba(0,0,0,0)`
            })
        }else if(band === '11'){
            return({
                valToColor: value => 
                ((value >= -100 & value < -90) & (value>=min & value<=max))? `rgb(0,0,0)`: 
                ((value >= -90 & value < -75) & (value>=min & value<=max))?`rgb(255,255,255)`:
                ((value >= -75 & value < -70) & (value>=min & value<=max))?`rgb(0, 0, ${51*value+3825})`:
                ((value >= -70 & value < -50) & (value>=min & value<=max))?`rgb(${-2.75*value+62.5}, ${-2.75*value+62.5}, ${-2.75*value+62.5})`:
                ((value >= -50 & value < -45) & (value>=min & value<=max))?`rgb(0, ${21*value+1200}, 0)`:
                ((value >= -45 & value < -35) & (value>=min & value<=max))?`rgb(100, 255, ${10.5*value+622.5})`:
                ((value >= -35 & value < -25) & (value>=min & value<=max))?`rgb(${19.5*value+742.5}, 0, ${19.5*value+742.5})`:
                ((value >= -25 & value < -20) & (value>=min & value<=max))?`rgb(${-17*value-170}, 0, 0)`:
                ((value >= -20 & value < -15) & (value>=min & value<=max))?`rgb(${-17*value+510}, ${-17*value+510}, 0)`:
                ((value >= -15 & value < 100) & (value>=min & value<=max))?`rgb(${-2.217*value+221.73}, ${-2.217*value+221.73}, ${-2.217*value+221.73})`:`rgba(0,0,0,0)`,
            })
        }else if(band === '12' || band === '13' || band === '14' || band === '15' || band === '16'){
            return({
                valToColor: value => 
                ((value >= -100 & value < -85) & (value>=min & value<=max))? `rgb(255,255,255)`: 
                ((value >= -85 & value < -80) & (value>=min & value<=max))?`rgb(${28*value+2495},${26*value+2210},${28*value+2495})`:
                ((value >= -80 & value < -75) & (value>=min & value<=max))?`rgb(${-51*value-3825}, ${-51*value-3825}, ${-51*value-3825})`:
                ((value >= -75 & value < -60) & (value>=min & value<=max))?`rgb(255, ${17*value+1275}, 0)`:
                ((value >= -60 & value < -50) & (value>=min & value<=max))?`rgb(${-25.5*value-1275}, 255, 0)`:
                ((value >= -50 & value < -45) & (value>=min & value<=max))?`rgb(0, ${-51*value-2295}, 255)`:
                ((value >= -45 & value < -40) & (value>=min & value<=max))?`rgb(0, 0, ${51*value+2295})`:
                ((value >= -40 & value < -25) & (value>=min & value<=max))?`rgb(0, ${17*value+680}, 255)`:
                ((value >= -25 & value < 100) & (value>=min & value<=max))?`rgb(${-2.04*value+204},${-2.04*value+204},${-2.04*value+204})`: `rgba(0,0,0,0)`
            })
        }
    }else if(type === 'L2'){     
        if (l2Product.var === 'RRQPE'){
            return({
                valToColor: value =>
                ((value >= 0 & value <= 12) & (value>=min & value<=max))?`rgb(0,${21.25*value},255)`:
                ((value > 12 & value <= 20) & (value>=min & value<=max))?`rgb(0,255,${-31.875*value+637.5})`:
                ((value > 20 & value <= 23) & (value>=min & value<=max))?`rgb(${85*value-1700}, 255, 0)`:
                ((value > 23 & value <= 35) & (value>=min & value<=max))?`rgb(255, ${-21.25*value+743.75}, 0)`:
                (value > 35)?`rgb(200, 0, 0)`: null
            })
        }

    }
    
}

export default getColor