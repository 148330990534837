const markSlider = {
    opacity : [{
        value: 0,
        label: '0'
    },{
        value: 0.5,
        label: '0.5'
    },{
        value: 1,
        label: '1'
    }],
    bright : [{
        value: 0,
        label: '0%'
    },{
        value: 50,
        label: '50%'
    },{
        value: 100,
        label: '100%'
    }],
    resolution : [{
        value: 64,
        label: '64'
    },{
        value: 128,
        label: '128'
    },{
        value: 192,
        label: '192'
    },{
        value: 256,
        label: '256'
    }]
}

export default markSlider