import {makeStyles} from '@mui/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "column",
            justifyContent:'center',
            alignItems:'center',
            width: '80%',
            backgroundColor:'white'
        },
    }
}))