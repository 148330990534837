import React, { useState, useEffect } from 'react'
import uuid from 'uuid-random'
import { MenuItem, FormControl} from '@mui/material'
import { CustomSelect, paperSelector, containers } from '../customComponents/select'
import { textBox } from '../customComponents/label.js'

const Selector = ({fn, disabled, products, _hint, type}) => {
    const mainContainer = containers().main
    const label = textBox().label

    const [selectedProduct, setSelectedProduct] = useState('')

    const handleClic = (event) =>{
        var index = event.target.value
        setSelectedProduct(index)
        fn(index)
    }

    useEffect(() => {
        return () => {
            if(type === 'subproduct'){
                setSelectedProduct('')
            }
        }
    }, [products])

    useEffect(() => {
        return () => {
        }
    }, [selectedProduct])

    return (
        <div className={`${mainContainer}`}>
            <FormControl fullWidth >
                <CustomSelect 
                    displayEmpty 
                    variant='outlined' 
                    key={uuid()} 
                    value={selectedProduct} 
                    onChange={handleClic} 
                    MenuProps={
                        {
                            PaperProps: { style : paperSelector}                            
                        }
                    }
                >
                    <MenuItem disabled value="" ><span className={`${label}`}>{`${_hint}`}</span></MenuItem>
                    {products.map((item, index) => (
                        <MenuItem key={uuid()} value={index}><span className={`${label}`}>{`${item.desc}`}</span></MenuItem>
                    ))}
                </CustomSelect>
            </FormControl>
        </div>
    )
}

export default Selector