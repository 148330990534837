import { useEffect, useRef } from "react";
import proj4 from "proj4";
import { useLeafletContext } from "@react-leaflet/core";
import { useMap } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";

window.proj4 = proj4;

const GeotiffLayer = ({ arrayBuffer, options, setDrawn, setFileLoaded, setWithFlashes }) => {
    const geoTiffLayerRef = useRef()
    const context = useLeafletContext()
    const map = useMap()
    
    useEffect(() => {
        setFileLoaded(false)
        const container = context.layerContainer || context.map
        parseGeoraster(arrayBuffer).then((georaster) => {
            options.georaster = georaster
            geoTiffLayerRef.current = new GeoRasterLayer(options) 
            container.addLayer(geoTiffLayerRef.current)
            //map.fitBounds(geoTiffLayerRef.current.getBounds())
            setDrawn(true)
            setFileLoaded(true)
            setWithFlashes(true)
        })
        return () => {
            container.removeLayer(geoTiffLayerRef.current)
            setWithFlashes(false)
        }
    }, [context, map, options]);
    
    return null;
};

export default GeotiffLayer;