import {makeStyles} from '@mui/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            width: '84%',
            paddingTop:'10px'
        },
    },
}))