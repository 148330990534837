const product_type = [
    {product: 'band', desc: 'GOES-R L1B'},
    {product: 'L2', desc: 'GOES-R L2'}
]

const goes_bands = [
    {product: '01', desc: '01 - Visible (Azul)', wl: '0.47 um'},
    {product: '02', desc: '02 - Visible (Rojo)', wl: '0.64 um'},
    {product: '03', desc: '03 - NIR (Veggie)', wl: '0.86 um'},
    {product: '04', desc: '04 - NIR (Cirrus)', wl: '1.37 um'},
    {product: '05', desc: '05 - NIR (Nieve/Hielo)', wl: '1.6 um'},
    {product: '06', desc: '06 - NIR (Tamaño de Partícula)', wl: '2.2 um'},
    {product: '07', desc: '07 - IR (Onda Corta)', wl: '3.9 um'},
    {product: '08', desc: '08 - IR (TWV Nivel Superior)', wl: '6.2 um'},
    {product: '09', desc: '09 - IR (TWV Nivel Medio)', wl: '6.9 um'},
    {product: '10', desc: '10 - IR (TWV Nivel Inferior)', wl: '7.3 um'},
    {product: '11', desc: '11 - IR (Fase Nube Alta)', wl: '8.4 um'},
    {product: '12', desc: '12 - IR (Ozono)', wl: '9.6 um'},
    {product: '13', desc: '13 - IR (Onda Larga "Clean")', wl: '10.3 um'},
    {product: '14', desc: '14 - IR (Onda Larga)', wl: '11.2 um'},
    {product: '15', desc: '15 - IR (Onda Larga "Dirty")', wl: '12.3 um'},
    {product: '16', desc: '16 - IR (Onda Larga "CO2")', wl: '13.3 um'},
]

const goes_level2 = [
    {product: 'RRQPEF', var:'RRQPE', desc: 'Precipitacion Int. Estimada'},
]

const embalses = [
    {station_id: 1, station_name: 'QN1-Sheque'}, 
    {station_id: 2, station_name: 'QN2-Sheque'}, 
    {station_id: 3, station_name: 'QN1-Tamboraque'}, 
    {station_id: 4, station_name: 'QN2-Tamboraque'},
]


module.exports = {
    goes_bands,
    goes_level2,
    product_type,
    embalses
}