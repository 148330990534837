import React, {useState, useEffect, useRef} from 'react'
import styles from './styles.js'
import { io } from "socket.io-client";
import { product_type, goes_bands, goes_level2 } from '../../config/products'
import ControlTiff from '../ControlTiff/index.js'
import TimeBox from '../TimeBox/index.js';

const ImageViewer = ({socketServer, socketWs}) => {
    const socketRef = useRef()

    const [timeSlider, setTimeSlider] = useState({name: ``, actualValue:``})

    const container = styles().container
    const containerImage = styles().containerImage
    const imgStyle = styles().imgStyle
    const buttonStyle = styles().buttonStyle
    const buttonStartStyle = styles().buttonStartStyle
    
    const [l2Product, setL2] = useState({product:'', var: ''})
    const [band, setBand] = useState('01')
    const [productType, setProductType] = useState('')
    const [times, setTimes] = useState([])
    const [start, setStart] = useState(false)
    const [withTimes, setWithTimes] = useState(false)
    const [withImage, setWithImage] = useState(false)
    const [selectedTime, setSelectedTime] = useState('')
    const [marksTime, setMarksTime] = useState([])
    const [image, setImage] = useState('')
    const [imageIndex, setImageIndex] = useState(0)
    const [sliderValue, setSliderValue] = useState(null)
    const [speed, setSpeed] = useState(100)
    const [readyToLoad, setReadyToLoad] = useState(false)
    const [ready, setReady] = useState(false)
    const [endBuffer, setEndBuffer] = useState(false)
    const [transferStarted, setTransferStarted] = useState(false)
    const [byteFrom, setByteFrom] = useState(0)
    const [sizeTransfered, setSizeTransfered] = useState(0)
    const [loadingFile, setLoadingFile] = useState(false)
    const [chunks, setChunks] = useState([])
    const [arrayLen, setArrayLen] = useState(0)

    const intervalIdRef = useRef(0);

    const handleBack = () => {
        if(sliderValue>0){
            setImageIndex(sliderValue-1)
            setSelectedTime(times[sliderValue-1].time)
            setSliderValue(sliderValue-1)
        }
    }

    const handleForward = () => {
        if(sliderValue<marksTime.slice(-1)[0].value){
            setImageIndex(sliderValue+1)
            setSelectedTime(times[sliderValue+1].time)
            setSliderValue(sliderValue+1)
        }
    }

    const handleStart = () => {
        setStart(true)
    }

    const handleStop = () => {
        setStart(false)
    }

    const updateImage = async () =>{
        if(readyToLoad){
            if(productType === 'band'){
                socketRef.current.emit('getBandImage', {times, band})
            }else if(productType === 'L2'){
                socketRef.current.emit('getL2Image', {times, l2Product})
            }
            setReadyToLoad(false)
        }
    }

    const loadImage = async () => {
        if(selectedTime === ''){
            console.log('Seleccionar fecha')
            return
        }
        setWithImage(false)
        setEndBuffer(false)
        setTransferStarted(true)     
        setLoadingFile(true)
        if(productType === 'band'){    
            socketRef.current.emit('getBandImage', {byteFrom, date: selectedTime.date, time: selectedTime.time, band:band})
        }
        else if(productType === 'L2'){ 
            socketRef.current.emit('getL2Image', {byteFrom, date: selectedTime.date, time: selectedTime.time, l2Product:l2Product})
        }
    }

    const handleProducts = async (index) => {
        setProductType(product_type[index].product)
    }

    const handleBand = async (index) => {
        const band = goes_bands[index].product        
        setBand(band)
        socketRef.current.emit('getTimeBands', band)
    }

    const handleL2 = async (index) => {
        const payload = {product:goes_level2[index].product, var:goes_level2[index].var}
        setL2(payload)
        socketRef.current.emit('getL2Time', payload)
    }

    useEffect(() => {
        /*if(start){
            intervalIdRef.current = setInterval(() => {                
                if(sliderValue>=0 & sliderValue <= marksTime.slice(-1)[0].value){
                    if(sliderValue >= marksTime.slice(-1)[0].value){
                        setSelectedTime(times[0].time)
                        setSliderValue(0)
                        setImageIndex(0)
                    }else{
                        setImageIndex(sliderValue+1)
                        setSelectedTime(times[sliderValue+1].time)
                        setSliderValue(sliderValue+1)
                    }
                }
            }, (-9*speed+1000))
        }*/
        return () => clearInterval(intervalIdRef.current);
    },[band, l2Product, selectedTime, start, readyToLoad])

    useEffect(() => {
        socketRef.current = io(`wss://${socketServer}/geo-${socketWs}`,{
            path: '/geobrowser/products/',
            auth:{
                token: localStorage.getItem('token')
            }
        })

        socketRef.current.on('setTimeBands', times => {
            setTimes(times)
            setSelectedTime(times[0])
            setTimeSlider({name:`${times[0].date} ${times[0].time} UTC0`, actualValue: times[0]})
            setSliderValue(0)
            setWithTimes(true)
            const _marksTemp = []
            times.map((item, index) => (_marksTemp.push({value: index, label: ``})))
            setMarksTime(_marksTemp)
            setProductType('band')
            ready ? setReady(false) : setReady(true)
        })
        socketRef.current.on('setL2Time', times => {
            setTimes(times)
            setSelectedTime(times[0])
            setTimeSlider({name:`${times[0].date} ${times[0].time} UTC0`, actualValue: times[0]})
            setSliderValue(0)
            setWithTimes(true)
            const _marksTemp = []
            times.map((item, index) => (_marksTemp.push({value: index, label: ``})))
            setMarksTime(_marksTemp)
            setProductType('L2')
            ready ? setReady(false) : setReady(true)
        })
        socketRef.current.on('setBandImage', args => {
            var newByteFrom = byteFrom+args.bufferSize
            setChunks([...chunks, {chunk:args.chunk, offset:byteFrom}])
            setSizeTransfered(newByteFrom)
            setArrayLen(args.arraySize)
            if(!args.finished){
                setByteFrom(newByteFrom)

            }else{
                setByteFrom(0)
                setEndBuffer(true)
            }
            ready ? setReady(false) : setReady(true)
        })
        socketRef.current.on('setL2Image', args => {
            var newByteFrom = byteFrom+args.bufferSize
            setChunks([...chunks, {chunk:args.chunk, offset:byteFrom}])
            setSizeTransfered(newByteFrom)
            setArrayLen(args.arraySize)
            if(!args.finished){
                setByteFrom(newByteFrom)
            }else{
                setByteFrom(0)
                setEndBuffer(true)
            }
            ready ? setReady(false) : setReady(true)
        })

        if(transferStarted){   
            if(endBuffer){
                var _arrTemp = new Uint8Array(arrayLen)                
                chunks.forEach(item => {
                    _arrTemp.set(new Uint8Array(item.chunk), item.offset)
                })
                const blob = new Blob([_arrTemp])
                const srcBlob = URL.createObjectURL(blob)
                setChunks([])
                setTransferStarted(false)
                setSizeTransfered(0)
                setLoadingFile(false)
                setImage(srcBlob)
                setWithImage(true)
                setReadyToLoad(false)
                ready ? setReady(false) : setReady(true)
            }else{
                if(productType === 'band'){ 
                    socketRef.current.emit('getBandImage', {byteFrom, date: selectedTime.date, time: selectedTime.time, band:band})
                }else if (productType === 'L2'){
                    socketRef.current.emit('getL2Image', {byteFrom, date: selectedTime.date, time: selectedTime.time, l2Product})
                }
            }
        }

        return () => {
            socketRef.current.off('setTimeBands')
            socketRef.current.off('setL2Time')
            socketRef.current.off('setBandImage')
            socketRef.current.off('setL2Image')
            socketRef.current.disconnect()
        }
    }, [ready])

    var controlProps = {
        conditions:{
            productType: productType,
            loadingFile: loadingFile,
            withControls: false
        },
        productType:{
            disabled: loadingFile,
            fn: handleProducts,
            products: product_type,
            _hint: 'Seleccione una Categoria'
        },
        products_bands:{
            active: true,
            disabled: loadingFile,
            fn: handleBand,
            products: goes_bands,
            _hint: 'Seleccione una Banda'
        },
        products_l2: {  
            active: true,
            disabled: loadingFile,
            fn: handleL2,
            products: goes_level2, 
            _hint: 'Seleccione un Producto'
        },
        loadButton:{
            onClick: loadImage
        },
        progressBar:{
            progressValue:!isFinite(sizeTransfered/arrayLen)?0:((sizeTransfered/arrayLen)*100)
        }
    }

    return (
        <div className={`${container}`}>
            <ControlTiff controlProps={controlProps} />
            {withTimes?<TimeBox dateTimes={times} setDateTime={setSelectedTime} actualDateTime={selectedTime}/>:null}   
            {/*<div className={`${containerLeft}`}>
                <Typography variant='h6' >Bandas IR - VIS</Typography>
                <Selector fn={handleBand} disabled={loadingFile}/>
                <Typography variant='h6' >Nivel 2</Typography>
                <Button disabled={loadingFile} className={`${buttonStartStyle}`} key={uuid()} variant='outlined' color='primary' onClick={()=>handleL2({product:'RRQPEF', var:'RRQPE'})}>
                            {'RRQPEF'}
                </Button>
                <Button disabled={loadingFile} className={`${buttonStartStyle}`} key={uuid()} variant='outlined' color='primary' onClick={()=>loadImage()}>
                            {'Cargar'}
                </Button>
                !withTimes?null:<SliderSelector disabled={!withImage} name='Velocidad' prop='speed' state={{min:0, max:0}} fn={setSpeed} defaultValue={100} min={0} max={100} step={1} marks={[{value:0, label:'0%'},{value:100, label:'100%'}]} type='single'/>*/}
                {/*<Button disabled={!withTimes || start || !withImage} className={`${buttonStartStyle}`} key={uuid()} variant='contained' color='primary' onClick={()=>handleStart()}>
                            {'Iniciar'}
                </Button>
                <Button disabled={!start} className={`${buttonStartStyle}`} key={uuid()} variant='contained' color='primary' onClick={()=>handleStop()}>
                            {'Detener'}
    </Button>
            </div>*/}
            {withImage?
                    <div className={`${containerImage}`}>
                        <img alt='goes-r' src={image} className={`${imgStyle}`}/>
                    </div>:null}
        </div>
    )
}

export default ImageViewer