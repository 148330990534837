import React, { useEffect, useState}  from 'react'
import { Circle, Popup, Polyline } from "react-leaflet";
import uuid from 'uuid-random'

const Flashes = ({ socketRef, props, setBussy}) => {

    const [f, setF] = useState({})
    const [g, setG] = useState({})
    const [byteFrom, setByteFrom] = useState(0)
    const [chunks, setChunks] = useState([])
    const [arrayLen, setArrayLen] = useState(0)
    const [sizeTransfered, setSizeTransfered] = useState(0)
    const [ready, setReady] = useState(false)
    const [endBuffer, setEndBuffer] = useState(false)
    const [productType, setProductType] = useState('')
    const [withArrayBuffer, setWithArrayBuffer] = useState(false)
    const [transferStarted, setTransferStarted] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)
    const [fileLoaded, setFileLoaded] = useState(false)
    const [flashReady, setFlashReady] = useState(false)

    const getTimes = () => {
        socketRef.current.emit('getGLMTime', {product: 'GLM'})
    }

    const getFlashes = () => {
        setBussy(true)
        setProductType('Flashes')
        setLoadingFile(true)
        setFileLoaded(false)
        setEndBuffer(false)
        setTransferStarted(true)
        setWithArrayBuffer(false)
        socketRef.current.emit('getGLMBuffer', {byteFrom, date: props.selectedTimeFlash.date, time: props.selectedTimeFlash.time, l2Product: {product:'GLM', var:'F'}})
    }

    const getGroups = () => {
        setBussy(true)
        setProductType('Groups')
        setLoadingFile(true)
        setFileLoaded(false)
        setEndBuffer(false)
        setTransferStarted(true)
        setWithArrayBuffer(false)
        socketRef.current.emit('getGLMBuffer', {byteFrom, date: props.selectedTimeFlash.date, time: props.selectedTimeFlash.time, l2Product: {product:'GLM', var:'G'}})
    }
    
    useEffect(() => {
        if(props.selectedTimeFlash){
            getFlashes()
        }
        return () => {                     
        }
    }, [props.selectedTimeFlash])
    
    useEffect(() => {
        if(!props.withTimesFlash)
            getTimes()      
        socketRef.current.on('setGLMTime', times => {
            props.setTimesFlash(times)
            props.setSelectedTimeFlash(times[6])
            props.setWithTimesFlash(true)       
        })
        return () => {          
            socketRef.current.off('setGLMTime')  
            props.setTimesFlash({})
            props.setSelectedTimeFlash(null)
            props.setWithTimesFlash(false)               
        }
    }, [])

    useEffect(() => {
        if(transferStarted){
            if(endBuffer){
                var _arrTemp = new Uint8Array(arrayLen)     
                chunks.forEach(item => {
                    _arrTemp.set(new Uint8Array(item.chunk), item.offset)
                })
                var enc = new TextDecoder("utf-8")
                const pre_product = enc.decode(_arrTemp)
                const final_product = pre_product.substring(0, pre_product.length-1)
                if(productType === 'Flashes'){ 
                    setF(JSON.parse(final_product))
                    setFlashReady(true)
                }else if (productType === 'Groups'){
                    setG(JSON.parse(final_product))
                    setFlashReady(false)
                    setBussy(false)
                }      
                setChunks([])    
                setWithArrayBuffer(true)
                setTransferStarted(false)
                setSizeTransfered(0)
                setLoadingFile(false)
                setFileLoaded(true)
                ready ? setReady(false) : setReady(true)
            }else{
                if(productType === 'Flashes'){ 
                    socketRef.current.emit('getGLMBuffer', {byteFrom, date: props.selectedTimeFlash.date, time: props.selectedTimeFlash.time, l2Product: {product:'GLM', var:'F'}})
                }else if (productType === 'Groups'){
                    socketRef.current.emit('getGLMBuffer', {byteFrom, date: props.selectedTimeFlash.date, time: props.selectedTimeFlash.time, l2Product: {product:'GLM', var:'G'}})
                }
                ready ? setReady(false) : setReady(true)
            }
        }

        socketRef.current.on('setGLMBuffer', async args => {
            var newByteFrom = byteFrom+args.bufferSize
            setChunks([...chunks, {chunk:args.chunk, offset:byteFrom}])
            setSizeTransfered(newByteFrom)
            setArrayLen(args.arraySize)
            if(!args.finished){
                setByteFrom(newByteFrom)
            }else{
                setByteFrom(0)
                setEndBuffer(true)
            }
            ready ? setReady(false) : setReady(true)
        })
        return () => {          
            socketRef.current.off('setGLMBuffer')   
        }
    }, [ready])

    useEffect(() => {
        if(flashReady){
            getGroups()
        }
    },[flashReady])

    return (
        <>  
            {
            f.flashes?f.flashes.map(item => (        
                <Circle key={uuid()} center={[item.lat, item.lon]} radius={Math.sqrt(item.area/Math.PI)} color='black'>
                    <Popup key={uuid()}>
                        <span>{`Latitud: ${item.lat.toFixed(4)}`}</span><br/>
                        <span>{`Longitud: ${item.lon.toFixed(4)}`}</span><br/>
                        <span>{`Area: ${(item.area*1e-6).toFixed(2)} km2`}</span><br/>
                        <span>{`Energía: ${(item.energy*1e+15).toFixed(2)} fJ`}</span><br/>
                    </Popup>
                </Circle>            
            )):null   
            }                  
            {
            g.groups?g.groups.map((item, index) => (
                <Circle key={uuid()} center={[item.lat, item.lon]} radius={200} color='white'/>
            )):null    
            }
        </>  
    );
}
export default Flashes