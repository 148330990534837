import {fromArrayBuffer} from 'geotiff';

const getArrayBuffer = (dataBuffer) => new Promise(async (resolve, reject) => {
    var arrayBuffer
    var tiffProps
    var withArrayBuffer = false
    var arrayBufferLen = 0
    try{
        const buf =  dataBuffer
        arrayBufferLen = buf.byteLength
        arrayBuffer = new ArrayBuffer(arrayBufferLen)
        new Uint8Array(arrayBuffer).set(new Uint8Array(buf))
        const tiff = await fromArrayBuffer(arrayBuffer)
        const image = await tiff.getImage()
        tiffProps = {
            width: image.getWidth(),
            height: image.getHeight(),
            tileWidth : image.getTileWidth(),
            tileHeight: image.getTileHeight(),
            samplesPerPixel: image.getSamplesPerPixel(),
            origin: image.getOrigin(),
            resolution: image.getResolution(),
            bbox: image.getBoundingBox()
        }
        withArrayBuffer = true
        resolve ({arrayBuffer, withArrayBuffer, arrayBufferLen, tiffProps})
    }catch(err){
        reject (err)
    }
})

export default getArrayBuffer