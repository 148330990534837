import React from 'react'
import ProgressBar from './ProgressBar'
import { CustomButton } from './customComponents/loadingButton'
import RangeSlider from './RangeSlider'
import Selector from './Selector'
import SingleSlider from './SingleSlider'
import uuid from 'uuid-random'
import styles from './styles'

const ControlTiff = ({controlProps}) => {
    const containerLeft = styles().containerLeft
    const controlsContainer = styles().controlsContainer

    return (
        <div className={`${containerLeft}`}>
            {<Selector 
                disabled={controlProps.productType.disabled} 
                fn={controlProps.productType.fn} 
                products={controlProps.productType.products} 
                _hint={controlProps.productType._hint} 
                type='productType'
            />}      
            {controlProps.conditions.productType === 'band' ?
                <Selector 
                    disabled={controlProps.products_bands.disabled} 
                    fn={controlProps.products_bands.fn} 
                    products={controlProps.products_bands.products} 
                    _hint={controlProps.products_bands._hint}
                    type='subproduct'
                    />:
            controlProps.conditions.productType === 'L2' ? 
                <Selector 
                    disabled={controlProps.products_l2.disabled} 
                    fn={controlProps.products_l2.fn} 
                    products={controlProps.products_l2.products} 
                    _hint={controlProps.products_l2._hint}
                    type='subproduct'
                    />:
                null
            }
            {controlProps.conditions.withControls?<div className={`${controlsContainer}`}>
                <SingleSlider 
                    disabled={controlProps.opacity.disabled}
                    name={controlProps.opacity.name}
                    key={uuid()} 
                    sliderOpts={controlProps.opacity.sliderOpts} 
                    configSlider={controlProps.opacity.configSlider} 
                    defaultValue={controlProps.opacity.defaultValue} 
                    min={controlProps.opacity.min} 
                    max={controlProps.opacity.max} 
                    step={controlProps.opacity.step} 
                    marks={controlProps.opacity.marks}  
                    icon={controlProps.opacity.icon}
                />
                <SingleSlider 
                    disabled={controlProps.brightness.disabled} 
                    name={controlProps.brightness.name}
                    key={uuid()} 
                    sliderOpts={controlProps.brightness.sliderOpts} 
                    configSlider={controlProps.brightness.configSlider} 
                    defaultValue={controlProps.brightness.defaultValue} 
                    min={controlProps.brightness.min} 
                    max={controlProps.brightness.max} 
                    step={controlProps.brightness.step} 
                    marks={controlProps.brightness.marks}  
                    icon={controlProps.brightness.icon}
                />
                <SingleSlider 
                    disabled={controlProps.resolution.disabled} 
                    name={controlProps.resolution.name}
                    key={uuid()} 
                    sliderOpts={controlProps.resolution.sliderOpts} 
                    configSlider={controlProps.resolution.configSlider} 
                    defaultValue={controlProps.resolution.defaultValue} 
                    min={controlProps.resolution.min} 
                    max={controlProps.resolution.max} 
                    step={controlProps.resolution.step} 
                    marks={controlProps.resolution.marks}  
                    icon={controlProps.resolution.icon}
                />                   
                <RangeSlider 
                    disabled={controlProps.variable.disabled} 
                    name={controlProps.variable.name} 
                    key={uuid()} 
                    sliderOpts={controlProps.variable.sliderOpts} 
                    configSlider={controlProps.variable.configSlider} 
                    defaultValue={controlProps.variable.defaultValue} 
                    min={controlProps.variable.min} 
                    max={controlProps.variable.max} 
                    step={controlProps.variable.step} 
                    marks={controlProps.variable.marks} 
                    icon={controlProps.variable.icon}
                />
            </div>:null}   
            {
                !controlProps.conditions.loadingFile
                ?<CustomButton variant='contained' onClick={controlProps.loadButton.onClick} >Cargar</CustomButton>
                :<ProgressBar progressValue={controlProps.progressBar.progressValue}/>
            }          
        </div>
    )
}

export default ControlTiff