export const bar_01_06 = () => {
    var values = []
    for (var i = 0; i <= 1.01; i+=0.01){
        values.push(Math.round(i*100)/100)
    }
    return values
}

export const bar_07_16 = () => {
    var values = []
    for (var i = -100; i <= 100; i++){
        values.push(i)
    }
    return values
}

export const RRQPE = () => {
    var values = []
    for (var i = 0; i <= 35.1; i+=0.1){
        values.push(Math.round(i*10)/10)
    }
    return values
}