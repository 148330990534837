import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

const boxWidths = MainTheme.boxWidths
const paddingSizes = MainTheme.paddingSizes

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection:"row",
            justifyContent:'flex-center',
            alignItems:'flex-center',
            position:'absolute',
            height:'20px',
            bottom: '30px',
            left:'60px',
            borderRadius: 10,
            width: boxWidths.lg,
            padding: paddingSizes.lg,
            paddingLeft: '20px',
            paddingRight: '20px',
            opacity:'0.95',
            backgroundColor: MainTheme.palette.secondary.main,
            zIndex: 2
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: boxWidths.xl,
            paddingBottom: paddingSizes.xl
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            width: boxWidths.xxl,
            paddingBottom: paddingSizes.xxl
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            width: boxWidths.xxxl,
            paddingBottom: paddingSizes.xxxl
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            width: boxWidths.xxxxl,
            paddingBottom: paddingSizes.xxxxl
        },
    },
    colorContainer:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: 'flex',
            position: 'relative',
            flexDirection:"column",
            justifyContent:'flex-center',
            alignItems:'flex-center',
            width: '100%',
            height:'10px',
        },
    },
    tooltipContainer:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection:"column",
            justifyContent:'flex-center',
            alignItems:'flex-center',
            width: '10px',
            height:'10px',
            position: 'absolute',
            top: '15px',
            right: '0px',
            zIndex: '2'
        },
    }
}))