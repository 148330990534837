import { Dashboard } from './components/Dashboard';
import './App.css'

function App() {
    return (                  
        <div className='App'>
            <Dashboard />
        </div>
    )
}

export default App;
