import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

const paddingSizes = MainTheme.paddingSizes
const paddingBoxSizes = MainTheme.paddingBoxSizes
const boxWidths = MainTheme.boxWidths

export default makeStyles(() => ({
    containerLeft:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            justifyContent:'flex-start',
            alignItems:'center',
            position:'absolute',
            top: '10px',
            left:'60px',
            zIndex:'2',
            borderRadius: 10,
            backgroundColor: MainTheme.palette.secondary.main,
            opacity:'0.95',
            width: boxWidths.lg,
            paddingBottom: paddingSizes.lg
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: boxWidths.xl,
            paddingBottom: paddingSizes.xl
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            width: boxWidths.xxl,
            paddingBottom: paddingSizes.xxl
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            width: boxWidths.xxxl,
            paddingBottom: paddingSizes.xxxl
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            width: boxWidths.xxxxl,
            paddingBottom: paddingSizes.xxxxl
        },
    },
    controlsContainer:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection:"column",
            justifyContent:'center',
            alignItems:'center',
            borderRadius: 3,
            border: `1px solid ${MainTheme.palette.secondary.contrastText}`,
            marginTop: paddingSizes.lg,
            paddingBottom: paddingBoxSizes.lg,
            width: '88%',
        },
        [MainTheme.breakpoints.up('xl')]: {
            marginTop: paddingSizes.xl,
            paddingBottom: paddingBoxSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            marginTop: paddingSizes.xxl,
            paddingBottom: paddingBoxSizes.xxl,
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            marginTop: paddingSizes.xxxl,
            paddingBottom: paddingBoxSizes.xxxl,
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            marginTop: paddingSizes.xxxxl,
            paddingBottom: paddingBoxSizes.xxxxl,
        },
    },
}))