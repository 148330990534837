import React, {useEffect, useState, useRef} from 'react'
import styles from './styles.js'
import uuid from 'uuid-random'
import EmabalseButton from './EmbalseButton/index.js'
import { Typography } from '@mui/material'
import EmbalseTable from './EmbalseTable/index.js'
import EmbalseChart from './EmbalseChart/index.js'
import { io } from "socket.io-client";
import { embalses } from '../../config/products.js'

const EmbalsesViewer = ({socketServer, socketWs}) => {
    const socketRef = useRef()

    const container = styles().container
    const containerLeft = styles().containerLeft
    const containerRight = styles().containerRight

    const [readyToShow, setReadyToShow] = useState(false)
    const [withYears, setWithYears] = useState(false)
    const [stationId, setStationId] = useState(0)
    const [yearSelected, setYearSelected] = useState(0)
    const [years, setYears] = useState([])
    const [historyData, setHistoryData] = useState([])
    const [ready, setReady] = useState(false)

    const handleClick = async (name, id) => {
        setReadyToShow(false)
        setStationId(id)
        socketRef.current.emit('getEmbTimes', id)
    }

    const loadData = async (name, id) => {
        setReadyToShow(false)
        setYearSelected(years[id].year)
        socketRef.current.emit('getEmbHistory', {stationId, year:years[id].year})
    }

    useEffect(() => {
        if(historyData.length !== 0)
            setReadyToShow(true)
    }, [historyData])

    useEffect(()=>{
        socketRef.current = io(`wss://${socketServer}/geo-${socketWs}`, {
            path: '/geobrowser/data/',
            auth:{
                token: localStorage.getItem('token')
            }
        })
        socketRef.current.on('setEmbTimes', args => {
            setYears(args)
            setWithYears(true)
            ready ? setReady(false) : setReady(true)
        })
        socketRef.current.on('setEmbHistory', args => {
            setHistoryData(args)
            ready ? setReady(false) : setReady(true)
        })

        return () => {
            socketRef.current.off('setEmbTimes')
            socketRef.current.off('setEmbHistory')
            socketRef.current.disconnect()
        }
    }, [ready])

    return (
        <div className={`${container}`}>
            <div className={`${containerLeft}`}>
                <Typography variant='h6'>Emablses</Typography>
                {
                    embalses.map(item => (
                        <EmabalseButton key={uuid()} _name={item.station_name} _id={item.station_id} onClick={handleClick}/>
                    ))
                }
                {withYears?<Typography variant='h6'>Años</Typography>:null}
                {
                    years.map((item, index) => (
                        <EmabalseButton key={uuid()} _name={item.year} _id={index} onClick={loadData}/>
                    ))
                }
            </div>
            <div className={`${containerRight}`}>
                {readyToShow?
                <>
                    <EmbalseTable year={yearSelected} data={historyData}/>
                    <EmbalseChart data={historyData}/>
                </>:<Typography>Seleccione un año</Typography>}
            </div>
        </div>
    )
}

export default EmbalsesViewer