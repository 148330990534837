import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

const paddingSizes = MainTheme.paddingSizes
const paddingBoxSizes = MainTheme.paddingBoxSizes
const boxWidths = MainTheme.boxWidths
const iconSizes = MainTheme.iconMenuSizes
const iconTimer = MainTheme.iconTimer
const paddings = MainTheme.paddingSizes
const timeBoxWidths = MainTheme.timeBoxWidths

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection:"row",
            justifyContent:'flex-start',
            alignItems:'flex-start',
            position: 'relative',
            width: '100%',
            height:'100vh',
        },
    },
    containerLeft:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            justifyContent:'flex-start',
            alignItems:'center',
            position:'absolute',
            top: '10px',
            left:'60px',
            zIndex:'2',
            borderRadius: 10,
            backgroundColor: MainTheme.palette.secondary.main,
            opacity:'0.95',
            width: boxWidths.lg,
            paddingBottom: paddingSizes.lg
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: boxWidths.xl,
            paddingBottom: paddingSizes.xl
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            width: boxWidths.xxl,
            paddingBottom: paddingSizes.xxl
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            width: boxWidths.xxxl,
            paddingBottom: paddingSizes.xxxl
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            width: boxWidths.xxxxl,
            paddingBottom: paddingSizes.xxxxl
        },
    },
    containerRight:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'center',  
            width: '100%',
            height:'100vh',
            zIndex:'1',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    timeBoxContainer:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            position:'absolute',
            bottom: '10%',
            left:'60px',
            width: timeBoxWidths.lg,
            zIndex:'3',
            borderRadius: 10,
            overflow:'hidden',
            backgroundColor: MainTheme.palette.secondary.main,
            opacity:'0.95',
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: timeBoxWidths.xl,    
            bottom: '10%',
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            width: timeBoxWidths.xxl,    
            bottom: '7%',
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            width: timeBoxWidths.xxxl,    
            bottom: '5%',
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            width: timeBoxWidths.xxxxl,    
            bottom: '4%',
        },
    },
    timeBoxContainerFlash:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            position:'absolute',
            bottom: '10%',
            right:'60px',
            width: timeBoxWidths.lg,
            zIndex:'3',
            borderRadius: 10,
            overflow:'hidden',
            backgroundColor: MainTheme.palette.secondary.main,
            opacity:'0.95',
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: timeBoxWidths.xl,    
            bottom: '10%',
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            width: timeBoxWidths.xxl,    
            bottom: '7%',
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            width: timeBoxWidths.xxxl,    
            bottom: '5%',
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            width: timeBoxWidths.xxxxl,    
            bottom: '4%',
        },
    },
    controlsContainer:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection:"column",
            justifyContent:'center',
            alignItems:'center',
            borderRadius: 3,
            border: `1px solid ${MainTheme.palette.secondary.contrastText}`,
            marginTop: paddingSizes.lg,
            paddingBottom: paddingBoxSizes.lg,
            width: '88%',
        },
        [MainTheme.breakpoints.up('xl')]: {
            marginTop: paddingSizes.xl,
            paddingBottom: paddingBoxSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            marginTop: paddingSizes.xxl,
            paddingBottom: paddingBoxSizes.xxl,
        },
        [MainTheme.breakpoints.up('xxxl')]: { 
            marginTop: paddingSizes.xxxl,
            paddingBottom: paddingBoxSizes.xxxl,
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            marginTop: paddingSizes.xxxxl,
            paddingBottom: paddingBoxSizes.xxxxl,
        },
    },
    textBox:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            fontSize:'14px',
            color: MainTheme.palette.primary.contrastText,
        }
    },
    floatButton:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            justifyContent:'flex-start',
            alignItems:'center',
            borderRadius: 3,
            border:'1px solid gray',
            position:'absolute',
            right: '15px',
            top:'60px',
            zIndex:'2',
        }
    },
    icon:{
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.iconSizes.xs,             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.iconSizes.sm,            
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.iconSizes.md,            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            fontSize: iconSizes.lg,
            color:'white',
            height: iconTimer.height.lg,
            width: iconTimer.width.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:iconSizes.xl,    
            height: iconTimer.height.xl,
            width: iconTimer.width.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:iconSizes.xxl,   
            height: iconTimer.height.xxl,
            width: iconTimer.width.xxl
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:iconSizes.xxxl,   
            height: iconTimer.height.xxxl,
            width: iconTimer.width.xxxl 
        },
        [MainTheme.breakpoints.up('xxxxl')]: {
            fontSize:iconSizes.xxxxl,   
            height: iconTimer.height.xxxxl,
            width: iconTimer.width.xxxxl
        },
    },
}))