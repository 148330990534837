import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

const iconSizes = MainTheme.iconMenuSizes
const iconTimer = MainTheme.iconTimer
const paddings = MainTheme.paddingSizes
const menuBarSizes = MainTheme.menuBarSizes

export const container = makeStyles(() => ({
    main:{
        [MainTheme.breakpoints.up('xs')]: {          
        },
        [MainTheme.breakpoints.up('sm')]: {           
        },
        [MainTheme.breakpoints.up('md')]: {            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection:"column",
            justifyContent:'flex-start',
            alignItems:'center',
            width: menuBarSizes.lg,
            height:'100vh',
            backgroundColor: MainTheme.palette.secondary.main,
        },
        [MainTheme.breakpoints.up('xl')]: {
            width: menuBarSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {   
            width: menuBarSizes.xxl,  
        },
        [MainTheme.breakpoints.up('xxxl')]: {   
            width: menuBarSizes.xxxl, 
        },
        [MainTheme.breakpoints.up('xxxxl')]: {   
            width: menuBarSizes.xxxxl,
        },
    },
    icon:{
        [MainTheme.breakpoints.up('xs')]: { 
            fontSize:MainTheme.iconSizes.xs,             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            fontSize:MainTheme.iconSizes.sm,            
        },
        [MainTheme.breakpoints.up('md')]: {  
            fontSize:MainTheme.iconSizes.md,            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            fontSize: iconSizes.lg,
            color:'white',
            height: iconTimer.height.lg,
            width: iconTimer.width.lg,
            paddingTop: paddings.lg   
        },
        [MainTheme.breakpoints.up('xl')]: {
            fontSize:iconSizes.xl,    
            height: iconTimer.height.xl,
            width: iconTimer.width.xl,
            paddingTop: paddings.xl   
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            fontSize:iconSizes.xxl,   
            height: iconTimer.height.xxl,
            width: iconTimer.width.xxl   ,
            paddingTop: paddings.xxl         
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            fontSize:iconSizes.xxxl,   
            height: iconTimer.height.xxxl,
            width: iconTimer.width.xxxl     ,
            paddingTop: paddings.xxxl       
        },
        [MainTheme.breakpoints.up('xxxxl')]: {
            fontSize:iconSizes.xxxxl,   
            height: iconTimer.height.xxxxl,
            width: iconTimer.width.xxxxl  ,
            paddingTop: paddings.xxxxl            
        },
    },
}))