import {makeStyles} from '@mui/styles'
import MainTheme from '../../MainTheme'

const paddingSizes = MainTheme.paddingSizes
const boxWidths = MainTheme.boxWidths

export const container = makeStyles(() => ({
    main:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "column",
            justifyContent:'center',
            alignItems:'center',
        }
    },
}))