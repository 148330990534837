import {makeStyles, styled} from '@mui/styles'
import MainTheme from '../../MainTheme'
import {Select} from '@mui/material'

const selectIconSizes = MainTheme.selectIconSizes
const selectHeights = MainTheme.selectHeights
const paddingSizes = MainTheme.paddingSizes

export const containers = makeStyles(() => ({
    main:{
        [MainTheme.breakpoints.up('xs')]: { 
             
        },
        [MainTheme.breakpoints.up('sm')]: {  
            
        },
        [MainTheme.breakpoints.up('md')]: {  
            
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent:'center',
            alignItems:'center',
            width: '90%',
            paddingTop: paddingSizes.lg,
        },
        [MainTheme.breakpoints.up('xl')]: {
            paddingTop: paddingSizes.xl,
        },
        [MainTheme.breakpoints.up('xxl')]: {  
            paddingTop: paddingSizes.xxl,
        },
        [MainTheme.breakpoints.up('xxxl')]: {  
            paddingTop: paddingSizes.xxxl,
        },
        [MainTheme.breakpoints.up('xxxxl')]: { 
            paddingTop: paddingSizes.xxxxl,            
        },
    },
}))

export const paperSelector = {
    backgroundColor: MainTheme.palette.secondary.main,
}

export const CustomSelect = styled(Select)(() => ({
    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            border: '1px solid white',
        },
        "&:hover fieldset": {
            border: '2px solid white'
        },
        "&.Mui-focused fieldset": {
            border: '1px solid white'
        },
        "& .MuiSelect-icon":{
            color:'white',
        }
    },
    [MainTheme.breakpoints.up('xs')]: { 
        "&.MuiOutlinedInput-root":{
            height: selectHeights.xs
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.xs
        }
    },
    [MainTheme.breakpoints.up('sm')]: {  
        "&.MuiOutlinedInput-root":{
            height: selectHeights.sm
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.sm
        }
    },
    [MainTheme.breakpoints.up('md')]: {  
        "&.MuiOutlinedInput-root":{
            height: selectHeights.md
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.md
        }        
    },
    [MainTheme.breakpoints.up('lg')]: {
        "&.MuiOutlinedInput-root":{
            height: selectHeights.lg
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.lg
        }
    },
    [MainTheme.breakpoints.up('xl')]: {
        "&.MuiOutlinedInput-root":{
            height: selectHeights.xl
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.xl
        }
    },
    [MainTheme.breakpoints.up('xxl')]: {  
        "&.MuiOutlinedInput-root":{
            height: selectHeights.xxl
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.xxl
        }        
    },
    [MainTheme.breakpoints.up('xxxl')]: {  
        "&.MuiOutlinedInput-root":{
            height: selectHeights.xxxl
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.xxxl
        }        
    },
    [MainTheme.breakpoints.up('xxxxl')]: {  
        "&.MuiOutlinedInput-root":{
            height: selectHeights.xxxxl
        },
        "& .MuiSelect-icon":{
            fontSize:selectIconSizes.xxxxl
        }        
    },
}))