import React from 'react'
import { IconButton } from '@mui/material'
import { container } from '../styles'

const ControlButton = ({props}) => {
    const icon = container().icon
    const Icon = props.icon

    return (        
        <IconButton onClick={props.onClick}>
            <div className={`${icon}`}>
                <Icon fontSize='inherit'/>
            </div>
        </IconButton>  
    )
}

export default ControlButton