import React from 'react'
import { IconButton } from '@mui/material'
import styles from './styles'

const FloatButton = ({props, withFlashes}) => {
    const container = styles().floatButton
    const icon = styles().icon
    const Icon = props.icon

    const backgroundColorOn = 'red'
    const backgroundColorOff = 'black'

    return (        
        <div className={`${container}`} style={withFlashes?{backgroundColor: backgroundColorOn}:{backgroundColor:backgroundColorOff}}>
            <IconButton onClick={props.onClick}>
                <div className={`${icon}`}>
                    <Icon fontSize='inherit'/>
                </div>
            </IconButton>  
        </div>
    )
}

export default FloatButton