import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection:"row",
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width: '100%',
            height:'100vh',
        },
    }
}))