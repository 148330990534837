import clienteAxios from './axios'
import axiosEmbalses from './embalses'
import authAxios from './auth';

const tokenAuth = token =>{
    if(token){
        clienteAxios.defaults.headers.common['x-auth-token'] = token;
        axiosEmbalses.defaults.headers.common['x-auth-token'] = token;
        authAxios.defaults.headers.common['x-auth-token'] = token;
    }else{
        delete clienteAxios.defaults.headers.common['x-auth-token']
        delete axiosEmbalses.defaults.headers.common['x-auth-token']
        delete authAxios.defaults.headers.common['x-auth-token']
    }
}

export default tokenAuth