import React, {useState} from 'react'
import styles from './styles.js'
import { Button, TextField, Typography, Snackbar } from '@mui/material'
import uuid from 'uuid-random'
import {startSession} from './auth'

const Login = ({setLogedIn, setDisplay, setUserLoged, formDisabled, setFormDisabled}) => {
    const container = styles().container
    const formContainer = styles().formContainer

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [snackOn, setSanckOn] = useState(false)
    const [snackMessage, setSnackMessage] = useState('')

    const logIn = async () => {
        try{
            setFormDisabled(true)
            const res = await startSession({username, password})
            if(res.message){
                setSnackMessage(res.message)
                setSanckOn(true)
                setFormDisabled(false)
                return
            }else{
                setPassword('')
                setLogedIn(true)
                setUserLoged(username)
                setDisplay({map:true, jpg:false, embalses:false, login:false})
            }
        }catch(err){
            console.log(err)
        }
    }

    const snackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }      
        setSanckOn(false);
    }    

    return (
        <div className={`${container}`}>
            <div className={`${formContainer}`}>
                <Typography variant='h6' color='primary' style={{marginBottom:'10px'}}>Accede a tu cuenta</Typography>
                <TextField disabled={formDisabled} id={uuid()} value={username} label="Usuario" variant="outlined" style={{paddingBottom: '10px'}} onChange={event=>{setUsername(event.target.value)}} />
                <TextField disabled={formDisabled} id={uuid()} value={password} label="Contraseña" variant="outlined" type='password' style={{paddingBottom: '10px'}} onChange={event=>{setPassword(event.target.value)}}/>
                <Button disabled={formDisabled} variant='contained' color='primary' onClick={()=>logIn()}>Ingresar</Button>
                <Snackbar open={snackOn} autoHideDuration={2000} onClose={() => snackClose()} message={snackMessage} />
            </div>
        </div>
    )
}

export default Login