import React, {useState, useEffect} from 'react'
import { usuarioAutenticado } from '../Login/auth.js'
import Maps from '../Maps/index.js'
import styles from './styles.js'
import MenuLeft from '../MenuLeft/index.js'
import ImageViewer from '../ImageViewer/index'
import EmbalsesViewer from '../EmbalsesViewer/index.js'
import Login from '../Login/index.js'
import uuid from 'uuid-random'

export const Dashboard = () => {

    const container = styles().container
    const [socketServer, setSocketServer] = useState('servicios.adrtecnology.com')
    const [socketWs, setSocketWs] = useState(uuid())
    const [display, setDisplay] = useState({
        login: true,
        map: false,
        jpg:false,
        embalses: false
    })
    const [logedIn, setLogedIn] = useState(false)
    const [userLoged, setUserLoged] = useState('')
    const [formDisabled, setFormDisabled] = useState(true)

    useEffect(() => {
        const testLogedIn = async () => {
            if(!logedIn){
                try{
                    const res = await usuarioAutenticado()
                    if(res.message){
                        setFormDisabled(false)
                        return
                    }else if(res.username && res.id){
                        setLogedIn(true)
                        setUserLoged(true)
                        setFormDisabled(false)
                        setDisplay({...display, map:true, jpg:false, embalses:false, login:false})
                    }
                }catch(err){
                    return
                }
            }
        }
        testLogedIn()
    }, [])
    

    return (
        <div className={`${container}`}>    
            {logedIn?<MenuLeft state={display} fn={setDisplay} setLogedIn={setLogedIn} setFormDisabled={setFormDisabled} />:null}
            {display.login?<Login setLogedIn={setLogedIn} setDisplay={setDisplay} setUserLoged={setUserLoged} formDisabled={formDisabled} setFormDisabled={setFormDisabled} />:null}
            {display.map?<Maps socketWs={socketWs} socketServer={socketServer}/>:null}
            {display.jpg?<ImageViewer socketWs={socketWs} socketServer={socketServer}/>:null}
            {display.embalses?<EmbalsesViewer socketWs={socketWs} socketServer={socketServer}/>:null}
        </div>
    )
}
