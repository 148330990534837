import { LinearProgress } from '@mui/material'
import styles from './styles'
import React from 'react'
import { textBox } from '../customComponents/label'

const ProgressBar = ({progressValue}) => {
    const container = styles().container
    const label = textBox().label

    return (
        <div className={`${container}`}>
            <LinearProgress variant="determinate" value={progressValue}/>
            <span className={`${label}`}>{`Cargando ${progressValue.toFixed(1)}%`}</span>
        </div>
    )
}

export default ProgressBar