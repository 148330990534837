import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width: '100%',
            height: '100vh',
            backgroundColor:'white'
        },
    },
    containerLeft:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            justifyContent:'flex-start',
            alignItems:'center',
            width: '15%',
            height:'100vh',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    containerRight:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'space-around',
            width: '85%',            
            height:'100vh',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    buttonStyle:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingTop:'10px', 
            paddingBottom:'10px', 
            paddingLeft:'25px',  
            marginTop:'10px', 
            backgroundColor:'white',
            width:'90%'
        },
    }
}))