import {makeStyles} from '@mui/styles'
import MainTheme from '../MainTheme'

export default makeStyles(() => ({
    container:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'flex-start',
            alignItems:'flex-start',
            width: '100%',
            height: '100vh',
            backgroundColor:'white',
            position: 'relative',
        },
    },
    containerLeft:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            justifyContent:'flex-start',
            alignItems:'center',
            width: '15%',
            height:'100vh',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    containerRight:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'center',
            width: '100%',            
            height:'100vh',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    containerDown:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: "row",
            justifyContent:'center',
            alignItems:'center',
            width: '100%',
            backgroundColor: MainTheme.palette.primary.contrastText,
        },
    },
    containerImage:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            display: "flex",
            flexDirection: 'row',
            alignItems:'center',
            justifyContent:'center',
            width: '95%',
            height: '100%',
            color: MainTheme.palette.primary.main,
            backgroundColor: MainTheme.palette.secondary.contrastText,
        },
    },
    buttonStyle:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
            marginTop:'10px', 
            marginRight:'40px',
            marginLeft:'40px',
        },
    },
    buttonStartStyle:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            marginTop:'20px',
        },
    },
    imgStyle: {
        width: '50%', /* or any custom size */
        objectFit: 'contain'
    }
}))