import React from 'react'
import ControlButton from '../ControlButton'
import { textBox } from '../../ControlTiff/customComponents/label'
import { container } from './styles'

const month = ['Ene', 'Feb', 'Mar', 'Abr', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

const DateControl = ({dateProps, buttonForward, buttonBack, disabled}) => {
    const mainContainer = container().main
    const label = textBox().label
    const index = parseInt(dateProps.split(' ')[0])-1
    const date = dateProps.split(' ')[1]

    return (
        <div className={`${mainContainer}`}>
            <ControlButton disabled={disabled} props={buttonForward}/>
            <span className={`${label}`}>{`${month[index]} ${date}`}</span>
            <ControlButton disabled={disabled} props={buttonBack}/>
        </div>
    )
}

export default DateControl