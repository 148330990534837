import React from 'react'
import { IconButton } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { container } from '../styles'

const ControlButton = ({props, disabled}) => {
    const icon = container().icon

    return (        
        <IconButton disabled={disabled} onClick={props.onClick}>
            <div className={`${icon}`}>
                {props.type==='forward'?
                    <ArrowDropUp fontSize='inherit'/>:
                props.type==='back'?
                    <ArrowDropDown fontSize='inherit'/>:
                null}
            </div>
        </IconButton>  
    )
}

export default ControlButton