import { Button } from '@mui/material'
import React from 'react'
import { container } from './styles.js'
import { Public, PictureInPicture, LocalDrink, ExitToApp } from '@mui/icons-material'
import { menuButtons } from '../../config/buttonOpts.js'
import ControlButton from './ControlButton/index.js'

const MenuLeft = ({state, fn, setLogedIn, setFormDisabled}) => {
    const mainContainer = container().main

    const handleMap = () =>{
        fn({...state, map:true, jpg:false, embalses:false, login:false})
    }
    const handleJpg = () =>{
        fn({...state, map:false, jpg:true, embalses:false, login:false})
    }
    const handleEmbalses = () =>{
        fn({...state, map:false, jpg:false, embalses:true, login:false})
    }
    const handleLogout = () =>{
        localStorage.removeItem('token')
        setFormDisabled(false)
        setLogedIn(false)
        fn({...state, map:false, jpg:false, embalses:false, login:true})
    }

    const buttonMap = {
        onClick: handleMap,
        icon: Public
    }
    const buttonJpg = {
        onClick: handleJpg,
        icon: PictureInPicture
    }
    const buttonEmbalses = {
        onClick: handleEmbalses,
        icon: LocalDrink
    }
    const buttonLogout = {
        onClick: handleLogout,
        icon: ExitToApp
    }

    return (
        <div className={`${mainContainer}`}>
            <ControlButton key={0} name='map' props={buttonMap}/>
            <ControlButton key={1} name='jpg' props={buttonJpg}/>
            <ControlButton key={2} name='embalses' props={buttonEmbalses}/>
            <ControlButton key={3} name='logout' props={buttonLogout}/>
        </div>
    )
}

export default MenuLeft