import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material'
import React from 'react'
import uuid from 'uuid-random'
import styles from './styles.js'
const monthName = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

const EmbalseTable = ({year, data}) => {
    const container = styles().container

    return (
        <div className={`${container}`}>
            <Typography>{`Caudales Naturalizados (m3/s)`}</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography style={{fontWeight:'bold'}}>{year}</Typography></TableCell>
                            {monthName.map(item => (<TableCell key={uuid()} align="right"><Typography style={{fontWeight:'bold'}}>{item}</Typography></TableCell>))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><Typography style={{fontWeight:'bold'}}>{data[0].station.station_name}</Typography></TableCell>
                            {data.map(item => (<TableCell key={uuid()} align="right">{item.val}</TableCell>))}
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography style={{fontWeight:'bold'}}>{'Promedio'}</Typography></TableCell>
                            {data.map(item => (<TableCell key={uuid()} align="right">{item.med}</TableCell>))}
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography style={{fontWeight:'bold'}}>{'Máximo'}</Typography></TableCell>
                            {data.map(item => (<TableCell key={uuid()} align="right">{item.max}</TableCell>))}
                        </TableRow>
                        <TableRow>
                            <TableCell><Typography style={{fontWeight:'bold'}}>{'Mínimo'}</Typography></TableCell>
                            {data.map(item => (<TableCell key={uuid()} align="right">{item.min}</TableCell>))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default EmbalseTable