import React from 'react'
import { IconButton } from '@mui/material'
import { SkipNext } from '@mui/icons-material'
import { container } from '../styles'

const LastButton = ({props, disabled}) => {
    const icon = container().icon

    return (        
        <IconButton disabled={disabled} onClick={props.onClick}>
            <div className={`${icon}`}>
                <SkipNext fontSize='inherit'/>
            </div>
        </IconButton>  
    )
}

export default LastButton