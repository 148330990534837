import React from 'react'
import ControlButton from '../ControlButton'
import { textBox } from '../../ControlTiff/customComponents/label'
import { container } from './styles'

const HourControl = ({hourProps, buttonForward, buttonBack, disabled}) => {
    const mainContainer = container().main
    const label = textBox().label
    return (
        <div className={`${mainContainer}`}>
            <ControlButton disabled={disabled} props={buttonForward}/>
            <span className={`${label}`}>{`${hourProps}`}</span>
            <ControlButton disabled={disabled} props={buttonBack}/>
        </div>
    )
}

export default HourControl