import React from 'react'
import { CustomSlider, container } from '../customComponents/sliderSingle'
import { textBox } from '../customComponents/label'

const RangeSlider = ({defaultValue, sliderOpts, configSlider, min, max, step, name, marks, disabled, icon}) => {
    const mainContainer = container().main
    const iconContainer = container().icon
    const sliderContainer = container().slider
    const label = textBox().label
    const SliderIcon = icon

    const handleChangeCommited = (event, value) =>{
        configSlider({...sliderOpts, actualValue: value})
    }

    return (
        <div className={`${mainContainer}`}>
            <div className={`${iconContainer}`}>
                <SliderIcon fontSize='inherit' />
            </div>
            <div className={`${sliderContainer}`}>
                <span className={`${label}`}>{name}</span>
                <CustomSlider 
                    disabled={disabled}
                    size="small" 
                    defaultValue={defaultValue} 
                    valueLabelDisplay="auto" 
                    min={min} 
                    max={max} 
                    step={step}
                    onChangeCommitted={handleChangeCommited} 
                    marks={marks}
                />
            </div>
        </div>
    )
}

export default RangeSlider