import React, {useState, useEffect} from 'react'
import {bar_01_06, bar_07_16, RRQPE} from './colors'
import getColor from './palette'
import uuid from 'uuid-random'
import styles from './styles'
import { textBox } from './label'


const ColorBar = ({props}) => {
    var container = styles().container
    var colorContainer = styles().colorContainer
    var tooltipContainer = styles().tooltipContainer
    const label = textBox().label
    
    const [over, setOver] = useState(false)
    const [barValues, setBarValues] = useState(null)
    const [colorBar, setColorBar] = useState(null)
    const [toolTips, setToolTips] = useState(null)

    useEffect(() => {
        var color_bar
        var tooltips = []
        if(props.productType === 'band'){
            if(props.band === '01' || props.band === '02' || props.band === '03' || props.band === '04' || props.band === '05' || props.band === '06'){
                color_bar = bar_01_06()     
                setColorBar(color_bar)       
                color_bar.map((item, index) => {
                    if(index%10=== 0){
                        tooltips.push({index, val:item.toFixed(1)})
                    }
                })
                setToolTips(tooltips)
            }else if(props.band === '07' || props.band === '08' || props.band === '09' || props.band === '10' || props.band === '11' || props.band === '12' || props.band === '13' || props.band === '14' || props.band === '15' || props.band === '16'){
                color_bar = bar_07_16()       
                setColorBar(color_bar)               
                color_bar.map((item, index) => {
                    if(index%25=== 0){
                        tooltips.push({index, val:item.toFixed(0)})
                    }
                })
                setToolTips(tooltips)
            }
        }else if(props.productType === 'L2'){   
            if (props.l2Product.var === 'RRQPE'){
                color_bar = RRQPE()  
                setColorBar(color_bar)              
                color_bar.map((item, index) => {
                    if(index%50 === 0){
                        tooltips.push({index, val:item.toFixed(0)})
                    }
                })
                setToolTips(tooltips)
            }
        }

        return () => {     
            setColorBar(null)       
            setToolTips(null)
        } 
    }, [props.productType, props.band, props.l2Product.var])
    
    
    useEffect(() => {
        if(colorBar !== null){
            var _bar_values = []
            var fn_color = getColor({type: props.productType, l2Product: props.l2Product, band: props.band, min: props.min, max: props.max})
            colorBar.map(item => {
                const res = fn_color.valToColor(item)
                _bar_values.push({val: item, color: res})
            })
            setBarValues(_bar_values)
        }
        return () => {            
            setBarValues(null)
        } 
    }, [colorBar])

    const handleMouseOver = (item) => {
        setOver(true)
    }

    const handleMouseOut = () => {
        setOver(false)
    }

    return (
        <div className={`${container}`}>
            {barValues?barValues.map((item, index) => (
                <div key={uuid()} className={`${colorContainer}`} onMouseOver={() => handleMouseOver(item)} onMouseOut={handleMouseOut} style={{backgroundColor: item.color}}>
                    {toolTips?toolTips.map(t => (
                        index===t.index?
                        <div key={uuid()} className={`${tooltipContainer}`}>
                        <span className={`${label}`}>{t.val}</span></div>:null                                                  
                    )):null}
                </div>              
            )):null}      
        </div>
    )
}

export default ColorBar