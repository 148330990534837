import React from 'react';
import { Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const monthName = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']


const EmbalseChart = ({data}) => {
    const historyData = []
    const keys = [data[0].month, data[0].station.station_name, 'promedio', 'maximo', 'minimo']
    data.forEach( item => {
        historyData.push({
            name: monthName[item.month-1],
            [item.station.station_name]: item.val,
            promedio: item.med,
            maximo: item.max,
            minimo: item.min
        })
    })
    return (
        <>
            <Typography>{`Comparación de caudales`}</Typography>
            <ResponsiveContainer width="80%" height="60%">
                <LineChart
                width={500}
                height={300}
                data={historyData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={keys[1]} stroke="#C97272" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey={keys[2]} stroke="#7280C9" />
                <Line type="monotone" dataKey={keys[3]} stroke="#C9A972" />
                <Line type="monotone" dataKey={keys[4]} stroke="#BD72C9" />
                </LineChart>
            </ResponsiveContainer>
        </>
    )
}

export default EmbalseChart