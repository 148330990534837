import {makeStyles} from '@mui/styles'
import MainTheme from '../../MainTheme'

export default makeStyles(() => ({
    buttonStyle:{
        [MainTheme.breakpoints.down('sm')]: {  
        },
        [MainTheme.breakpoints.up('md')]: {
        },
        [MainTheme.breakpoints.up('lg')]: {
            paddingTop:'10px', 
            paddingBottom:'10px', 
            paddingLeft:'25px',  
            marginTop:'10px', 
            backgroundColor:'white',
            width:'90%'
        },
    }
}))