import React, {useState, useEffect} from 'react'
import  { textBox } from '../ControlTiff/customComponents/label'
import {container} from './styles'
import DateControl from './DateControl'
import HourControl from './HourControl'
import MinuteControl from './MinuteControl'
import LastButton from './LastButton'

const TimeBox = ({dateTimes, setDateTime, disabled}) => {

    var listItems = {
        years:[],
        months:[],
        dates:[],
        hours:[],
        minutes:[]
    }

    const [listDateTimes, setListDateTimes] = useState(listItems)
    const [indexList, setIndexList] = useState(6)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        dateTimes.map( item => {
            const year = item.date.split('-')[0]
            const month = item.date.split('-')[1]
            const date = item.date.split('-')[2]
            const hour = item.time.split('-')[0]
            const minute = item.time.split('-')[1]
            listItems.years.push(year)
            listItems.months.push(month)
            listItems.dates.push(date)
            listItems.hours.push(hour)
            listItems.minutes.push(minute)
            setListDateTimes(listItems)
            setIndexList(6)
            setReady(true)
        })
        return () => {
            setReady(false)
        }
    }, [dateTimes])
    

    const mainContainer = container().main
    const label = textBox().label

    const handleLastButton = () => {
        try{
            setDateTime(dateTimes[dateTimes.length-1])
            setIndexList(dateTimes.length-1)
            setNewDateTime(dateTimes.length-1)
        }catch(err){
            console.log(err)
        }
    }

    const setNewDateTime = (i) => {
        try{
            const newDateTime = {
                date: `${listDateTimes.years[i]}-${listDateTimes.months[i]}-${listDateTimes.dates[i]}`,
                time: `${listDateTimes.hours[i]}-${listDateTimes.minutes[i]}`
            }
            setDateTime(newDateTime)
        }catch(err){
            console.log(err)
        }
    }

    const getPrevIndex = (valueGroup, i) => {
        try{
            for(var counter=0; counter<valueGroup.length; counter++){
                if(!valueGroup[i-counter]){
                    return i
                }
                if(valueGroup[i] !== valueGroup[i-counter]){
                    return i-counter
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    const getNextIndex = (valueGroup, i) => {
        try{
            for(var counter=0; counter<valueGroup.length; counter++){
                if(!valueGroup[i+counter]){
                    return i
                }
                if(valueGroup[i] !== valueGroup[i+counter]){
                    return i+counter
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    const prevMinute = () => {
        try{
            const actualIndex = indexList -1
            if(actualIndex > 0){
                setIndexList(actualIndex)
                setNewDateTime(actualIndex)
            }
        }catch(err){
            console.log(err)
        }
    }

    const nextMinute = () => {
        try{
            const actualIndex = indexList + 1
            if(actualIndex <= listDateTimes.minutes.length-1){
                setIndexList(actualIndex)
                setNewDateTime(actualIndex)
            }
        }catch(err){
            console.log(err)
        }
    }

    const prevHour = () => {
        try{
            const newIndex = getPrevIndex(listDateTimes.hours, indexList)
            setIndexList(newIndex)
            setNewDateTime(newIndex)
        }catch(err){
            console.log(err)
        }
    }

    const nextHour = () => {
        try{
            const newIndex = getNextIndex(listDateTimes.hours, indexList)
            setIndexList(newIndex)
            setNewDateTime(newIndex)
        }catch(err){            
            console.log(err)
        }
    }

    const prevDate = () => {
        try{
            const newIndex = getPrevIndex(listDateTimes.dates, indexList)
            setIndexList(newIndex)
            setNewDateTime(newIndex)
        }catch(err){
            console.log(err)
        }
    }

    const nextDate = () => {
        try{
            const newIndex = getNextIndex(listDateTimes.dates, indexList)
            setIndexList(newIndex)
            setNewDateTime(newIndex)
        }catch(err){
            console.log(err)
        }
    }

    const minuteForward = {
        onClick: nextMinute,
        type: 'forward'
    }

    const minuteBack = {
        onClick: prevMinute,
        type: 'back'
    }

    const hourForward = {
        onClick: nextHour,
        type: 'forward'
    }

    const hourBack = {
        onClick: prevHour,
        type: 'back'
    }

    const dateForward = {
        onClick: nextDate,
        type: 'forward'
    }

    const dateBack = {
        onClick: prevDate,
        type: 'back'
    }

    const lastButton = {
        onClick: handleLastButton
    }

    return (
        <>
        {ready?<div className={`${mainContainer}`}>
            <DateControl disabled={disabled} dateProps={`${listDateTimes.months[indexList]} ${listDateTimes.dates[indexList]} `} buttonForward={dateForward} buttonBack={dateBack}/>
            <HourControl disabled={disabled} hourProps={listDateTimes.hours[indexList]} buttonForward={hourForward} buttonBack={hourBack}/>
            <span className={`${label}`}>:</span>
            <MinuteControl disabled={disabled} minuteProps={listDateTimes.minutes[indexList]} buttonForward={minuteForward} buttonBack={minuteBack}/>
            <LastButton disabled={disabled} props={lastButton} />
        </div>:null}</>
    )
}

export default TimeBox